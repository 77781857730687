import ApiService from "./apiservice";
import DBService from "./dbservice";
import { getFunctions, httpsCallable } from 'firebase/functions'
import app from '../firebase'
import { increment } from "firebase/firestore";

const WebService = {

	////////////////////////////////
	// STRIPE CONSTS
	////////////////////////////////
	stripePublishablekey: 'pk_live_51Nt7YGF2e3z9sGal4CgIDo7q36Lqyk8wF0rfTQWpVE4IFju3qKkuSIzHhvPYpR7a72ST7tRT3E61U8hVpK8M29a8002wspNPZD',

	basicMonthlyPriceId: 'price_1NvIL4F2e3z9sGalYgevxxLv',
	basicYearlyPriceId: 'price_1NvIQhF2e3z9sGalatLQ22zM',

	professionalMonthlyPriceId: 'price_1NvIOFF2e3z9sGalQ8tC64p5',
	professionalYearlyPriceId: 'price_1NvIOFF2e3z9sGalZ6G0wO0Y',

	businessMonthlyPriceId: 'price_1NvISKF2e3z9sGalWRIlqyD4',
	businessYearlyPriceId: 'price_1NvISLF2e3z9sGalcHmfbpPj',

	saveUserDetails(userDetails, userId) {
		return new Promise(async (resolve, reject) => {

			await DBService.saveUserDetails(userDetails, userId);

			resolve();

		});
	},

	updateDailyCounts(userId, incrementShops, incrementProducts) {
		return new Promise(async (resolve, reject) => {
			
			// Get the user details.
			let userDetails = await DBService.userDetails(userId);

			if (incrementShops) {
				userDetails.dailyShopCount = userDetails.dailyShopCount + 1;
			}

			if (incrementProducts) {
				userDetails.dailyProductCount = userDetails.dailyProductCount + 1;
			}

			if (!incrementShops && !incrementProducts) {

				// Get the user counts.
				const userCounts = await this.userCounts(userId);

				console.log(userCounts)

				// Set the allowance
				userDetails.allowanceDate = new Date();
				userDetails.dailyShopCount = userCounts.shops;
				userDetails.dailyProductCount = userCounts.products;

			}

			// Update the user details
			await DBService.saveUserDetails(userDetails, userId);

			resolve();

		});
	},

	userDetails(user){
		return new Promise(async (resolve, reject) => {
			
			// Get the subscriptions.
			const subscriptions = await this.getSubscriptions(user.uid); 

			// Get the user details.
			let userDetails = await DBService.userDetails(user.uid);

			// If the user has not set a first name and last name, check the user for these details as the provider might have them.
			if (userDetails.firstName == '' && userDetails.lastName == '' && user.displayName != null) {
				var nameArray = user.displayName.split(' ');
				if (nameArray.length > 0) {
					userDetails.firstName = nameArray[0];
				}
				if (nameArray.length > 1) {
					userDetails.lastName = nameArray[1];
				}
				await DBService.saveUserDetails(userDetails, user.uid);
			}

			// Get the daily limit.
			var diffInDays = 0;
			if (userDetails.allowanceDate != null) {
				diffInDays = Math.floor((new Date() - userDetails.allowanceDate.toDate())) / (1000 * 60 * 60 * 24);
			}

			if (userDetails.allowanceDate == null || diffInDays >= 1) {

				// Update the daily user counts.
				await this.updateDailyCounts(user.uid);

				// Update the outputted counts.
				let userCountDetails = await DBService.userDetails(user.uid);
				userDetails.allowanceDate = userCountDetails.allowanceDate;
				userDetails.dailyShopCount = userCountDetails.dailyShopCount;
				userDetails.dailyProductCount = userCountDetails.dailyProductCount;
			}

			let shopLimit = 0;
			let productLimit = 0;
			let subscriptionPrices = [];
			let hasActiveSubscription = false;
			let trailDaysRemaining = 0;
			let planName = '';
			subscriptions.forEach(subscription => {

				// Trail days.
				const trailEndDate = new Date(subscription.createdAt.setDate(subscription.createdAt.getDate() + 7));
				if (trailEndDate > new Date()) {
					const diff = trailEndDate.getTime() - new Date().getTime();   
					const daydiff = Math.floor(diff / (1000 * 60 * 60 * 24));   
					if (daydiff > trailDaysRemaining) {
						trailDaysRemaining = daydiff;
					}
				}

				// Status.
				if (subscription.status == 'active' || subscription.status == 'trialing') {
					hasActiveSubscription = true;
				}
				
				// Prices.
				subscription.prices.forEach(price => {
					subscriptionPrices.push(price);
				});

			});
			if (subscriptionPrices.indexOf(this.businessMonthlyPriceId) != -1 || subscriptionPrices.indexOf(this.businessYearlyPriceId) != -1 || userDetails.account == 'business') {
				shopLimit = 100;
				productLimit = 100;
				planName = 'Business';
			} else if (subscriptionPrices.indexOf(this.professionalMonthlyPriceId) != -1 || subscriptionPrices.indexOf(this.professionalYearlyPriceId) != -1 || userDetails.account == 'professional') {
				shopLimit = 50;
				productLimit = 50;
				planName = 'Professional';
			} else if (subscriptionPrices.indexOf(this.basicMonthlyPriceId) != -1 || subscriptionPrices.indexOf(this.basicYearlyPriceId) != -1 || userDetails.account == 'basic') {
				shopLimit = 15;
				productLimit = 15;
				planName = 'Basic';
			}

			resolve({
				firstName: userDetails.firstName,
				lastName: userDetails.lastName,
				trailDaysRemaining: trailDaysRemaining,
				hasActiveSubscription: hasActiveSubscription || userDetails.account == 'business'  || userDetails.account == 'professional'  || userDetails.account == 'basic',
				shopLimit: shopLimit,
				productLimit: productLimit,
				email: user.email,
				dailyShopCount: userDetails.dailyShopCount,
				dailyProductCount: userDetails.dailyProductCount,
				planName: planName
			});
		});
	},

	sendToCheckout(userId, priceId) {
		return new Promise(async (resolve, reject) => {

			const sessionId = await DBService.sendToCheckout(userId, priceId);

			resolve(sessionId);
		
		});
	},

	getPrices() {
		return new Promise(async (resolve, reject) => {

			const subscriptions = await DBService.prices();

			resolve(subscriptions);
		
		});
	},

	getSubscriptions(userId) {
		return new Promise(async (resolve, reject) => {

			let subscriptions = await DBService.subscriptions(userId);
			let userSubscriptions = [];
            subscriptions.forEach(subscription => {

                // Set the price ids.
                if (subscription && subscription.items && subscription.items.length && subscription.items.length > 0) {
                    let priceIds = [];
                    subscription.items.forEach(item => {
                        priceIds.push(item.price.id);
                    });
                    subscription.priceIds = priceIds;
                }
 
                // Set the status.
                let status = subscription.status;
                if (subscription?.cancel_at?.seconds != null && new Date(subscription.cancel_at.seconds * 1000) < new Date()) {
                    status = 'expired';
                } else if (subscription.cancel_at != null) {
                    status = 'cancelled';
                }

                // Create the user subscription.
                userSubscriptions.push({
                    prices: subscription.priceIds,
                    status: status,
					createdAt: new Date(subscription.created.seconds * 1000)
                });
                
            });

			resolve(userSubscriptions);
		
		});
	},

	async redirectToCustomerPortal() {
		return new Promise(async (resolve, reject) => {
			const functions = getFunctions(app, 'europe-west2');
			const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
			
			try {

				const { data } = await functionRef({
					returnUrl: window.location.origin,
					locale: "auto"
				});

				resolve(data.url);

			} catch (error) {
				console.error("Error calling the function:", error);
				reject('Unable to take you to your account. Please contact support');
			}
		});
	},

	/**
	 * User counts.
	 * @param {*} userId the user id.
	 * @returns 
	 */
	userCounts(userId) {
		return new Promise(async (resolve, reject) => {

			// Get the user products.
			const userProducts = await DBService.products(userId);

			// Get the user shop.
			const userShops = await DBService.shops(userId);

			resolve({
				shops: userShops.length,
				products: userProducts.length
			});
			
		});
	},

	/**
	 * Get the user products.
	 * @param {*} userId the user id.
	 */
	userProducts(userId) {
		return new Promise(async (resolve, reject) => {

			// Get the user products.
			const userProducts = await DBService.products(userId);

			resolve(userProducts);
			
		});
	},

	/**
     * Gets the store page data.
     * @param {*} storeId the store id.
     * @param {*} startDate the start date.
     * @param {*} endDate the end date.
     * @returns the store page data as promise.
     */
	storePage(storeId, startDate, endDate) {
		return new Promise(async (resolve, reject) => {

			const storePageResponse = await ApiService.storePage(storeId, startDate, endDate);

			resolve(storePageResponse);
			
		});
	},

	storePageProducts(storeId, startDate, endDate) {
		return new Promise(async (resolve, reject) => {

			const storePageProductsResponse = await ApiService.storePageProducts(storeId, startDate, endDate);

			// Format the product's revenue.
			if (storePageProductsResponse.top_products != null) {
				let dollar = new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
				});
				storePageProductsResponse.top_products.forEach(product => {
					product.total_revenue = dollar.format(product.total_revenue);
				});
			}

			// resolve(storePageResponse);

			resolve(storePageProductsResponse);

			
		});
	},

	/**
	 * Gets the shop stats.
     * @param {*} storeId the store id.
     * @param {*} startDate the start date.
     * @param {*} endDate the end date.
	 * @returns the shop stats as a promise.
	 */
	shopStats(shopId, startDate, endDate) {
		return new Promise(async (resolve, reject) => {

			// Get custom shop sales.
			//const customSalesResponse = await ApiService.shopSales(shopId, startDate, endDate);

			// // Set the chart data.
			// let customRevenueData = [];
			// let customCountData = []
			// customSalesResponse.forEach(date => {
			// 	customRevenueData.push([new Date(date.date).getTime(), date.total_revenue])
			// 	customCountData.push([new Date(date.date).getTime(), date.total_orders])
			// });

			// // Currency formatter.
			// let dollar = new Intl.NumberFormat('en-US', {
			// 	style: 'currency',
			// 	currency: 'USD',
			// });

			// // Sum up custom values.
			// let customAmount = 0;
			// let customCount = 0;
			// customSalesResponse.forEach(sales => {
			// 	customAmount += sales.total_revenue;
			// 	customCount += sales.total_orders
			// });

			// Get the tracked count.
			const trackByUserCount = await DBService.trackByUserCount(shopId);

			resolve({
				trackByUserCount: trackByUserCount,
				// customAmount: dollar.format(customAmount),
				// customCount: customCount.toLocaleString(),
				// customRevenueData: customRevenueData,
				// customCountData: customCountData
			});

		});
	},

	/**
	 * Adds a product.
	 * @param {*} productId the product id.
	 * @param {*} userId the user id.
 	 * @param {*} shopId the shop id.
	 * @returns the promise.
	 */
	addProduct(productId, userId, shopId) {
		return new Promise(async (resolve, reject) => {

			// Add the product to the DB.
			await DBService.addProduct({
				userId: userId,
				productId: productId,
				shopId: shopId,
				createdAt: new Date()
			});

			// Update the daily counts.
			await this.updateDailyCounts(userId, false, true);

			resolve();

		});
	},

	/**
	 * Remove a product.
	 * @param {*} productId the product id.
	 * @param {*} userId the user id.
	 * @returns the promise.
	 */
	removeProduct(productId, userId) {
		return new Promise(async (resolve, reject) => {

			// Add the product to the DB.
			await DBService.removeProduct(userId, productId);

			resolve();

		});
	},

	/**
	 * Remove a store.
	 * @param {*} shopId the shop id.
	 * @param {*} userId the user id.
	 * @returns the promise.
	 */
	removeStore(shopId, userId) {
		return new Promise(async (resolve, reject) => {

			// Add the store to the DB.
			await DBService.removeStore(userId, shopId);

			resolve();

		});
	},

	/**
	 * Adds a shop.
	 * @param {*} shopUrl the shop url.
	 * @param {*} userId the user id.
	 * @returns the promise.
	 */
	addShop(shopUrl, userId) {
		return new Promise(async (resolve, reject) => {

			// Add a shop.
			ApiService.addShop(shopUrl).then(response => {

				// Save the shop.
				DBService.addShop({
					userId: userId,
					shopId: response.id,
					createdAt: new Date()
				}).then(async response => {

					// Update the daily counts.
					await this.updateDailyCounts(userId, true, false);

					resolve();

				}, reason => {
					if (reason.error) {
						reject(reason.error);
					} else {
						reject('Something went wrong, please try again.')
					}
				});

			}, reason => {
				if (reason.error) {
					reject(reason.error);
				} else {
					reject('Something went wrontg, please try again.')
				}
			});

		});
	},

	/**
	 * Gets the shops with details.
	 * @param {*} userId the user id.
	 * @returns The shops with details as a promise.
	 */
	shopsWithDetails(userId) {
		return new Promise(async (resolve, reject) => {

			// Get all the shop id's from the db.
			const shopIds = (await DBService.shops(userId)).map(shop => shop.id).join();

			// Get the stores with sales data.
			let shopsWebResponse = [];
			if (shopIds != '') {
				shopsWebResponse = await ApiService.shopsWithDetails(shopIds);
			}

			// Check for errors.
			if (shopsWebResponse.error) {
				reject(shopsWebResponse.error);
			}

			resolve(shopsWebResponse);
		});
	},

	/**
	 * Gets the shops with details.
	 * @param {*} userId the user id.
	 * @returns The shops with details as a promise.
	 */	
	products(userId) {
		return new Promise(async (resolve, reject) => {

			// Get the user products.
			const products = await DBService.products(userId);

			// Get the product details.
			let productsWithDetails = [];
			let shopProducts = [];
			for (let i = 0; i < products.length; i++) {

				let productWithDetails = {
					id: products[i].id,
					shopId: products[i].shopId,
				};

				// Get the shop products.
			    const index = shopProducts.map(shop => shop.shopId).indexOf(products[i].shopId);
				if (index == -1) {

					const productsWebResponse = await ApiService.products(products[i].shopId);
					if (productsWebResponse.error) {
						reject(productsWebResponse.error);
					}

					shopProducts.push({
						shopId: products[i].shopId,
						products: productsWebResponse
					});

					const productIndex = productsWebResponse.map(product => product.product_id).indexOf(Number(products[i].id));
					if (productIndex != -1) {
						productWithDetails.img_url = productsWebResponse[productIndex].img_url;
						productWithDetails.product_title = productsWebResponse[productIndex].product_title;
						productWithDetails.updated_at = productsWebResponse[productIndex].updated_at;
						productWithDetails.variant_id = productsWebResponse[productIndex].variant_id;
						productWithDetails.variant_title = productsWebResponse[productIndex].variant_title;
					}

				} else {

					const productIndex = shopProducts[index].products.map(product => product.product_id).indexOf(Number(products[i].id));
					if (index != -1) {
						productWithDetails.img_url = shopProducts[index].products[productIndex].img_url;
						productWithDetails.product_title = shopProducts[index].products[productIndex].product_title;
						productWithDetails.updated_at = shopProducts[index].products[productIndex].updated_at;
						productWithDetails.variant_id = shopProducts[index].products[productIndex].variant_id;
						productWithDetails.variant_title = shopProducts[index].products[productIndex].variant_title;
					}

				}

				productsWithDetails.push(productWithDetails);

			}

			resolve(productsWithDetails)
		});	
	},

	/**
	 * Get a query parameter.
	 * @param {*} name The query parameter name.
	 * @returns The query parameter value.
	 */
	getQueryParam: function(name) {
		const queryParams = new URLSearchParams(window.location.search)
		const value = queryParams.get(name)
		return value
	},

	/**
	 * Set a query parameter.
	 * @param {*} name The query parameter name.
	 * @param {*} value The query parameter value.
	 * @param {*} addHistory If you want the browser history to be updated .
	 */
	setQueryParam: function(name, value, addHistory) {
		/// SET 
		// if ('URLSearchParams' in window) {
		// 	var searchParams = new URLSearchParams(window.location.search);
		// 	searchParams.set("foo", "bar");
		// 	window.location.search = searchParams.toString();
		// }





		



		// // Get the query string.
		// const [hash, query] = window.location.href.split('#')[1].split('?');

		// // Get the parameters.
		// let params = Object.fromEntries(new URLSearchParams(query))
		
		// // Check if the parameter already has this value.
		// if (params[name] == value) {
		// 	return;
		// }

		// // Set the new paramter value.
		// params[name] = value;

		// // Construct the the new url.
		// let newUrl = window.location.href.split('#')[0] + '#' +  hash;
		// let index = 0;
		// Object.keys(params).forEach(param => {
		// 	if (params[param] != null) {
		// 		if (index == 0) {
		// 			newUrl = newUrl + '?';
		// 		} else {
		// 			newUrl = newUrl + '&';
		// 		}
		// 		index++;
		// 		newUrl = newUrl + param + '=' + params[param];
		// 	}
		// });

		// // Set the new url.
		// if (addHistory) {
		// 	window.history.pushState({path: newUrl}, '', newUrl);
		// } else {
		// 	window.history.replaceState({path: newUrl}, '', newUrl);
		// }
	},

};

export default WebService;