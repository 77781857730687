import React from 'react';
import './wave.css';

export default class WaveIllustration extends React.Component {

    /**
     * Render.
     */
    render() {
        return (
          <svg id="Ebene_1" data-name="Ebene 1" viewBox="0 0 723.97 183.84">
            <defs>
              <linearGradient id="Unbenannter_Verlauf_2" data-name="Unbenannter Verlauf 2" x1="361.99" y1="183.84" x2="361.99" y2="0" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#10ce9c"/>
                <stop offset="1" stopColor="#00c6c6"/>
              </linearGradient>
            </defs>
            <path className="clswave-1" d="m684.9,27.03l-70.55-3.4-31.51,3.4L523.23,0l-20.86,6.82-14.78,20.2-68.97,16.35-36.24,74.22-17.36-4.73-15.62,11.55-13.83-6.82-88.88,4.72-52.22-19.95-52.84-18.9-60.18,10.87-43.15-6.15L.03,94.35C.02,122.98,0,151.61,0,180.25h0v3.6h723.97V11.55l-39.07,15.48Z"/>
          </svg>
        );
    };
};