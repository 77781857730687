import React from 'react';
import { Fragment } from 'react';
import { withRouter } from './withRouter';
import { Dialog, Menu, Transition } from '@headlessui/react'
import { XMarkIcon, Bars3Icon, HomeIcon, BuildingStorefrontIcon, ShoppingBagIcon, PlusIcon, UserCircleIcon, Cog6ToothIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom';
import TrackedCounts from './trackedcounts';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { toast } from 'react-toastify';
import WebService from '../services/webservice';
import { ChevronDownIcon } from '@heroicons/react/20/solid'

export default withRouter(class Sidebar extends React.Component {

    /**
     * Constructor.
     */
    constructor() {
        super();

        // Initial state.
        this.state = {
            sidebarOpen: false,
            loading: false,
            shops: null,
            products: null
        };

        // Bind functions.
        this.classNames = this.classNames.bind(this);
        this.userMarkUp = this.userMarkUp.bind(this);

        // The navigation links.
        this.nav = (
            <>
                <Link to={'/dashboard'}>
                    <li className={this.classNames(window.location.pathname == '/dashboard' ? 'bg-gray-50 text-emerald-600' : 'text-gray-700 hover:text-emerald-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
                        <HomeIcon className='text-gray-400 group-hover:text-emerald-600 h-6 w-6 shrink-0'/>
                        Dashboard
                    </li>
                </Link>

                <Link to={'/shops'}>
                    <li className={this.classNames(window.location.pathname == '/shops' ? 'bg-gray-50 text-emerald-600' : 'text-gray-700 hover:text-emerald-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
                        <BuildingStorefrontIcon className='text-gray-400 group-hover:text-emerald-600 h-6 w-6 shrink-0'/>
                        Tracked Shops
                    </li>
                </Link>

                <Link to={'/products'}>
                    <li className={this.classNames(window.location.pathname == '/products' ? 'bg-gray-50 text-emerald-600' : 'text-gray-700 hover:text-emerald-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
                        <ShoppingBagIcon className='text-gray-400 group-hover:text-emerald-600 h-6 w-6 shrink-0'/>
                        Tracked Products    
                    </li>
                </Link>

                <Link to={'/addshop'}>
                    <li className={this.classNames(window.location.pathname == '/addshop' ? 'bg-gray-50 text-emerald-600' : 'text-gray-700 hover:text-emerald-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
                        <PlusIcon className='text-gray-400 group-hover:text-emerald-600 h-6 w-6 shrink-0'/>
                        Add Shop
                    </li>
                </Link>
            </>
        );

        // The settings.
        this.settings = (
            <>
                <Link to={'/settings'}>
                    <li className={this.classNames(window.location.pathname == '/settings' ? 'bg-gray-50 text-emerald-600' : 'text-gray-700 hover:text-emerald-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
                        <Cog6ToothIcon className='text-gray-400 group-hover:text-emerald-600 h-6 w-6 shrink-0'/>
                        Settings
                    </li>
                </Link>
            </>
        );
    };

    /**
     * Get the remaining days.
     * @returns 
     */
    getTrailDaysRemaining() {
        if (this.props?.userDetails?.trailDaysRemaining != null) {
            return this.props.userDetails.trailDaysRemaining = 0;
        }
        return 0;
    };

    /**
     * Returns the given class names.
     * @param  {...any} classes the class names.
     * @returns the given class names.
     */
    classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    };

    userMarkUp() {
        return (
            // The user.
            this.user = (
                <>
                    <div className='flex items-center justify-center border-b' style={{height: '85px'}}>

                        <div className='h-12 w-12 flex items-center justify-center mr-2'>
                            <img src={require('../icon.png')} />
                        </div>

                        <Menu as="div" className="relative inline-block text-left">

                            <div>
                                <Menu.Button 
                                    className="inline-flex w-full items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50">
                                    
                                    <div className='mr-2' style={{width: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>    

                                        {/* Name */}
                                        <div className="text-sm font-semibold text-gray-900 fade-in" style={{textAlign: 'left'}}>
                                            {this.props?.userDetails?.firstName} {this.props?.userDetails?.lastName}
                                        </div>

                                        {/* Trail days */}
                                        {this.props?.userDetails != null && this.props.userDetails?.trailDaysRemaining > 0  ?
                                            <p className="text-xs text-gray-500" style={{textAlign: 'left'}}>{this.props?.userDetails?.planName} | {this.props?.userDetails?.trailDaysRemaining} Trail days left</p>
                                        :
                                            <p className="text-xs text-gray-500" style={{textAlign: 'left'}}>{this.props?.userDetails?.planName}</p>
                                        }

                                    </div>
                                    
                                    {/* Options */}
                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 fade-in" aria-hidden="true" />

                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                
                                    <div className="py-1">

                                        <Menu.Item>
                                            {({ active }) => (
                                                <div
                                                    onClick={() => {
                                                        signOut(auth).then(() => {
                                                            this.props.navigate('/login');
                                                        })
                                                    }}
                                                    href="#"
                                                    className={this.classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}>
                                                Log out
                                                </div>
                                            )}
                                        </Menu.Item>

                                    </div>

                                </Menu.Items>
                            </Transition>
                        </Menu>
                        
                    </div>
                </>
            )
        )
    };

    /**
     * Render.
     */
    render() {
        return (
            <>
            
                {/* Mobile */}
                <Transition.Root show={this.state.sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-50 lg:hidden" onClose={() => {
                        this.setState({
                            sidebarOpen: true
                        });
                    }}>

                        {/* Dark background */}
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full">

                                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">

                                    {/* Close button */}
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0">
                                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                            <button type="button" className="-m-2.5 p-2.5" onClick={() => {
                                                this.setState({
                                                    sidebarOpen: false
                                                });
                                            }}>
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>

                                    {/* Sidebar */}
                                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                              
                                        {/* User */}
                                        {this.userMarkUp()}
                                        
                                        <nav className="flex flex-1 flex-col">
                                            <ul role="list" className="flex flex-1 flex-col gap-y-2">
                                                
                                                {/* Nav */}
                                                {this.nav}

                                                {/* Settings */}
                                                <li className='mt-auto mb-4'>
                                                    <ul role="list" className=" space-y-1">
                                                        {this.settings}
                                                    </ul>
                                                </li>

                                            </ul>
                                            
                                        </nav>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>    
                    </Dialog>
                </Transition.Root>

                {/* Desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">

                        {/* User */}
                        {this.userMarkUp()}

                        <nav className="flex flex-1 flex-col">
                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                <li>
                                    <ul role="list" className="-mx-2 space-y-1">
                                        {this.nav}
                                    </ul>
                                </li>

                                {/* Settings */}
                                <li className='mt-auto mb-4'>
                                    <ul role="list" className="-mx-2 space-y-1">
                                        {this.settings}
                                    </ul>
                                </li>

                            </ul>
                        </nav>
                    </div>
                </div>

                {/* Mobile toolbar */}
                <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">

                    {/* Sidebar open button */}
                    <button 
                        type="button" 
                        className="-m-2.5 p-2.5 text-gray-700 lg:hidden" 
                        onClick={() => {
                            this.setState({
                                sidebarOpen: true
                            })
                        }}>
                            <span className="sr-only">Open sidebar</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    
                    <div className="flex-1">
                        <div className='flex items-center'>

                            {/* Title */}
                            <div className='text-sm font-semibold text-gray-900'>        
                                {this.props.title}
                            </div>
 
                            {/* Tracked counts */}
                            <div className='ml-auto flex'>
                                <TrackedCounts
                                    userDetails={this.props.userDetails} />
                            </div>

                        </div>
                    </div>

                </div>
            
            </>
            
         
        );
    };
});