import React from 'react';
import { Fragment } from 'react';
import { withRouter } from './withRouter';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

export default withRouter(class Lightbox extends React.Component {

    /**
     * Render.
     */
    render() {
        return (
            <Transition.Root show={true} as={Fragment}>

                <Dialog as="div" className="relative z-50" onClose={() => this.props.onDismiss()}>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
    
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">

                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => this.props.onDismiss()}>
                                                <span className="sr-only">Close</span>
                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>

                                    <div className="sm:flex sm:items-center">
                                        <div className="mt-3  sm:mt-0 w-full">

                                            {/* Title */}
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                {this.props.title}
                                            </Dialog.Title>
        
                                            {/* Body */}
                                            <div className="mt-2">
                                                {this.props.children}
                                            </div>

                                        </div>
                                    </div>

                                    {/* Button */}
                                    {this.props.buttons ?
                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                            {this.props.buttons}
                                        </div>
                                    : null}

                                </Dialog.Panel>

                            </Transition.Child>

                        </div>
                    </div>

                </Dialog>

            </Transition.Root>
        );
    };
});