import React from 'react';
import { Fragment } from 'react';
import { withRouter } from './withRouter';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import TrackedCounts from './trackedcounts';
import WebService from '../services/webservice';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { toast } from 'react-toastify';

export default withRouter(class Header extends React.Component {

    /**
     * Constructor.
     */
    constructor() {
        super();

        // Initial state.
        this.state = {
            loading: false,
            shops: null,
            products: null
        };
    };

    /**
     * Render.
     */
    render() {
        return (
            <div className="px-4 py-4 sm:px-6 lg:px-8 shadow bg-white hidden lg:block">
                <div className="mx-auto flex items-center justify-between gap-x-8 lg:mx-0">
                    <div className="flex items-center gap-x-6">

                        {/* Icon */}
                        <div className='h-12 w-12 flex items-center justify-center rounded-full ring-1 ring-gray-900/10 bg-emerald-500 text-white'>
                            {this.props.icon}
                        </div>

                        {/* Title */}
                        <h1>
                            <div className="text-base font-semibold text-gray-900">{this.props.title}</div>
                            <div className="text-sm text-gray-500">{this.props.subtitle}</div>
                        </h1>
                        
                    </div>

                    {/* Tracked counts */}
                    <div className="flex items-center gap-x-4 sm:gap-x-6">
                        <TrackedCounts
                            userDetails={this.props.userDetails} />
                    </div>

                </div>
            </div>
        );
    };
});