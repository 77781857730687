import React from 'react';
import { withRouter } from './withRouter';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { PlusIcon, ArrowPathIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-toastify';
import WebService from '../services/webservice';
import Limit from './limit';

export default withRouter(class AddShop extends React.Component {

    /**
     * Constructor.
     */
    constructor() {
        super();

        // Initial state.
        this.state = {
            shopUrl: '',
            shopUrlIsValid: true,
            addingStore: false,
            firstUrlUpdate: false,
            showLimit: false
        };

        // Bind functions.
        this.addShop = this.addShop.bind(this);
        this.checkUrl = this.checkUrl.bind(this);
    };

    /**
     * Check the shop url.
     * @param {*} url the url. 
     * @returns 
     */
    checkUrl(url) {
        const pattern = new RegExp(
            "^(https?:\\/\\/)?"+ // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|"+ // domain name and extension
            "((\\d{1,3}\\.){3}\\d{1,3}))"+ // OR ip (v4) address
            "(\\:\\d+)?"+ // port
            "(\\/[-a-z\\d%@_.~+&:]*)*"+ // path
            "(\\?[;&a-z\\d%@_.,~+&:=-]*)?"+ // query string
            "(\\#[-a-z\\d_]*)?$", "i" // fragment locator
        );
        return pattern.test(url);
    };

    /**
     * Add shop.
     */
    addShop(event) {

        // Prevent the default form action.
        event.preventDefault();
        
        // Prevent while loading.
        if (this.state.addingStore) {
            return;
        }

        // Check limits
        if (this.props.shops?.length > this.props.userDetails.shopLimit -1 ) {
            this.setState({
                showLimit: true
            })
            return;
        }
          
        // Check if the shop exists already.
        let shopWithOutHttp = this.state.shopUrl.replace('https://', '').replace('http://', '').replace('www.', '');

        let exists = this.props.shops?.some(shop => {
            const existingShopWithOutHttp = shop.url.replace('https://', '').replace('http://', '').replace('www.', '');;
            if (shopWithOutHttp == existingShopWithOutHttp) {
                return true;
            } else {
                return false;
            }
        });

        // let index = this.props.shops?.map(shop => shop.url).indexOf(shopWithOutHttp);
        // if (index != -1 && index != undefined) {
        if (exists) {   
            toast('You already have this shop');
            return;
        }

        // Set loading.
        this.setState({
            addingStore: true,
            firstUrlUpdate: true
        });

        // Add the shop.
        WebService.addShop(this.state.shopUrl, this.props.userId).then(response => {

            // Toast.
            toast('Shop added!');

            // Call the shop added action.
            this.props.shopAdded();

            // Update loaders.
            this.setState({
                addingStore: false,
            });

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                addingStore: false,
            });

        });
        
    };

    /**
     * Render.
     */
    render() {
        return (
            <>

                {/* Limit */}
                {this.state.showLimit ? 
                  <Limit 
                    onDismiss={() => 
                        this.setState({
                            showLimit: false
                        })
                    }/>
                : null}
              
                {/* Add shop */}
                {!this.props.loadingShops ?
                    <form className="mb-8 w-full" onSubmit={this.addShop}>
                        <div className="flex gap-x-4">
                            <div className='min-w-0 flex-auto'>
                                <div className="relative rounded-md shadow-sm">
                                    <input
                                        name="url"
                                        autoComplete="url"
                                        required
                                        className={!this.state.shopUrlIsValid && this.state.firstUrlUpdate ? 
                                            "w-full min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                                :
                                            "w-full min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-500 sm:text-sm sm:leading-6"
                                        }
                                        placeholder="Enter shopify domain"
                                        value={this.state.shopUrl}
                                        onBlur={() => {
                                            this.setState({
                                                firstUrlUpdate: true
                                            })
                                        }}
                                        onChange={(event) => {
                                            const url = event.target.value;
                                            this.setState({
                                                shopUrl: url,
                                                shopUrlIsValid: this.checkUrl(url)
                                            });
                                        }}/>
                                        {!this.state.shopUrlIsValid && this.state.firstUrlUpdate  ?
                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                            </div> 
                                        : null}
                                </div>

                                {!this.state.shopUrlIsValid && this.state.firstUrlUpdate ?
                                    <p className="mt-2 text-sm text-red-600" id="email-error">
                                        Enter a valid shopify domain.
                                    </p>
                                : null}

                            </div>
                            <div>
                                <button disabled={!this.state.shopUrlIsValid || this.state.shopUrl == null} type="submit" className="flex items-center rounded-md bg-emerald-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-500">
                                    <div>
                                        {!this.state.addingStore ?
                                            <PlusIcon className="h-4 w-4 [&>path]:stroke-[3]" />
                                        : 
                                            <ArrowPathIcon className="spin h-4 w-4 [&>path]:stroke-[3]" />
                                        }
                                    </div>
                                    <div className='ml-1'>
                                        Add shop
                                    </div>
                                </button>
                            </div>
                        </div>
                    </form>
                : null}
            </>
        );
    };
});