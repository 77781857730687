import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

// const people = [
//     { id: 1, name: 'Wade Cooper' },
//     { id: 2, name: 'Arlene Mccoy' },
//     { id: 3, name: 'Devon Webb' },
//     { id: 4, name: 'Tom Cook' },
//     { id: 5, name: 'Tanya Fox' },
//     { id: 6, name: 'Hellen Schmidt' },
//     { id: 7, name: 'Caroline Schultz' },
//     { id: 8, name: 'Mason Heaney' },
//     { id: 9, name: 'Claudie Smitham' },
//     { id: 10, name: 'Emil Schaefer' },
// ]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Select(props) {

    // const [selected, setSelected] = useState(people[3])

    return (
        <Listbox value={props.value} onChange={props.onChange}>
            {({ open }) => (
                <>

                    {/* Label */}
                    <Listbox.Label className="fade-in text-sm text-gray-500">Date range</Listbox.Label>

                    <div className="relative">

                        {/* Button */}
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-emerald-600 sm:text-sm sm:leading-6">
                            <span className="block truncate flex items-center">

                                {props.icon != null ?
                                    <div className='mr-2'>
                                        {props.icon}
                                    </div>
                                : null}
                                 
                                {props.value[props.labelProp]}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
            
                            {/* Options */}
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {props.options.map((option) => (
                                    <Listbox.Option
                                        key={option.id}
                                        className={({ active }) =>
                                            classNames(active ? 'bg-emerald-500 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-8 pr-4')
                                        }
                                        value={option}>
                                            {({ selected, active }) => (
                                                <>
                                                
                                                    <span className={classNames(props.value.id == option.id ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                        {option[props.labelProp]}
                                                    </span>

                                                    {props.value.id == option.id ? (
                                                        <span
                                                            className={classNames(
                                                            active ? 'text-white' : 'text-emerald-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5')}>
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}

                                                </>
                                            )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>

                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}