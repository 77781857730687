import { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';

const UserContext = createContext();

const AuthContextProvider = ({ children }) => {
    
    // State variables.
    const [user, setUser] = useState({});

    // Watch for user auth state changes.
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <UserContext.Provider value={{ user }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, AuthContextProvider }

export const UserAuth = () => {
    return useContext(UserContext);
};