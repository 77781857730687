import React from 'react';
import { withRouter } from '../components/withRouter';
import Sidebar from '../components/sidebar';
import { BuildingStorefrontIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { PlusIcon, ArrowRightIcon, ArrowPathIcon } from '@heroicons/react/24/outline'
import Header from '../components/header';
import { Link } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { db } from '../firebase';
import { collection, getDocs, query, setDoc, where, doc, addDoc } from "firebase/firestore";
import ApiService from '../services/apiservice.js';
import { ToastContainer, toast } from 'react-toastify';
import DBService from '../services/dbservice';
import Addshop from '../components/addshop';
import WebService from '../services/webservice';

export default withRouter(class ShopsPage extends React.Component {

    /**
     * Constructor.
     */
    constructor() {
        super();

        // Initial state.
        this.state = {
            shops: [],
            loadingShops: true,
            removingStore: false,
            removingStoreId: null,
            showHeader: true,
        };

        // Bind functions.
        this.getShops = this.getShops.bind(this);
        this.removeStore = this.removeStore.bind(this);

        // Set title
        document.title = 'Shops - Track Vision';
    };

    /**
     * Component did mount.
     */
    componentDidMount() {

        // Auth observer.
        this.authUnsubscribe = onAuthStateChanged(auth, user => {
            if (user) {

            // Check if the user has an active subscription.
            WebService.userDetails(user).then(userDetails => {
                if(!userDetails.hasActiveSubscription) {
                    this.props.navigate('/settings?tab=plan&showSelectAPlan=true');
                }
                this.setState({
                    userDetails: userDetails
                });
            }, error => {
                toast(error);
            });

                // Set the user.
                this.setState({
                    user: user
                }, () => {

                    // Get the shops.
                    this.getShops();

                });
            }
        });
    };

    /**
     * Component will unmount.
     */
    componentWillUnmount() {

        // Unsubscribe the auth observer.
        this.authUnsubscribe();
    };

    /**
     * Gets the shops.
     */
    getShops() {

        // Set loading.
        this.setState({
            loadingShops: true
        });

        // Get the shops.
        WebService.shopsWithDetails(this.state.user.uid).then(shops => {

            let dollar = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            });

            // Format numbers.
            shops.stores?.forEach(shop => {
                shop.total_orders_last_7_days = shop.total_orders_last_7_days.toLocaleString();
                shop.total_orders_last_30_days = shop.total_orders_last_30_days.toLocaleString();

                shop.total_orders_today = shop.total_orders_today.toLocaleString();
                shop.total_orders_yesterday = shop.total_orders_yesterday.toLocaleString();

                shop.total_revenue_last_7_days = dollar.format(shop.total_revenue_last_7_days);
                shop.total_revenue_last_30_days = dollar.format(shop.total_revenue_last_30_days);

                shop.total_revenue_today = dollar.format(shop.total_revenue_today);
                shop.total_revenue_yesterday = dollar.format(shop.total_revenue_yesterday);
                
            });

            // Add the stores to state.
            this.setState({
                shops: shops.stores,
                loadingShops: false
            });
            
        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                loadingShops: false
            });

        });
    };

    /**
     * Removes store.
     */
    removeStore(event, shopId) {

        // Prevent the default form action.
        event.preventDefault();

        // Prevent while loading.
        if (this.state.removingStore) {
            return;
        }

        // Set loading.
        this.setState({
            removingStore: true,
            removingStoreId: shopId
        });

        // Remove the store.
        WebService.removeStore(shopId, this.state.user?.uid).then(response => {

            // Toast.
            toast('Store removed!');

            // Update loaders.
            this.setState({
                removingStore: false,
                removingStoreId: null,
                showHeader: false
            }, () => {

                this.setState({
                    showHeader: true
                });

            });

            // Get the shops.
            this.getShops();

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                removingStore: false,
                removingStoreId: null
            });

        });

    };

    /**
     * Revenue.
     */
    render() {
        return (
            <>

                {/* Toast */}
                <ToastContainer autoClose={3000} theme='dark'/>

                {/* Sidebar */}
                {this.state.showHeader ?
                    <Sidebar
                        title='Tracked Shops'
                        userDetails={this.state.userDetails}/>        
                : null}

                {/* Content */}
                <main className="lg:pl-72">

                    {/* Header */}
                    {this.state.showHeader ?
                        <Header
                            title='Your Tracked Shops'
                            subtitle='Tracked Shops'
                            icon={<BuildingStorefrontIcon className='h-8 w-8 mb-1'/>}
                            userDetails={this.state.userDetails}/>
                    : null}
                    
                    {/* Main */}
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">

                        {/* Add shop */}
                        <Addshop
                            shops={this.state.shops}
                            userId={this.state.user?.uid}
                            loadingShops={this.state.loadingShops}
                            userDetails={this.state.userDetails}
                            shopAdded={() => {

                                // Get the shops.
                                this.setState({
                                    shops: [],
                                    showHeader: false
                                }, () => {
        
                                    this.setState({
                                        showHeader: true
                                    });

                                    // Get the user details.
                                    WebService.userDetails(this.state.user).then(userDetails => {
                                        
                                        this.setState({
                                            userDetails: userDetails
                                        });

                                        // Get the stores.
                                        this.getShops();

                                    }, error => {
                                        toast(error);
                                    });

                                });

                            }}/>

                        <div className='relative'>

                            {/* Loading */}
                            {this.state.loadingShops ?
                                <div className='absolute h-full rounded-lg w-full' >
                                    <div className='loading mb-6 w-full' style={{ height: '3rem' }}></div>
                                    <div className='loading mb-2 w-full' style={{ height: '3rem' }}></div>
                                        {[...Array(8)].map((obj, index) => (
                                            <div key={index} className='fade-in loading my-2 w-full' style={{ height: '4rem' }}></div>
                                        ))}
                                </div>
                            : null}
                            
                            {/* Shops */}
                            {this.state.shops?.length > 0 ?
                                <div className="rounded-lg shadow-sm ring-1 ring-gray-900/5 overflow-hidden fade-in">
                                    <div className="flow-root">
                                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                                                                Shop
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                Sales Today
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                Sales Yesterday
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                Sales 1 Week
                                                            </th>
                                                            <th scope="col" className="relative py-3.5 pl-3 pr-4">
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        {this.state.shops.map((shop, index)  => (
                                                            <tr key={index} className='hover:bg-slate-50' style={{ cursor: 'pointer', display: 'table-row' }}>
                                                                <td>
                                                                    <Link to={`/shop?id=${shop._id}`}>  
                                                                        <div className="flex items-center py-4 pl-4 pr-3">
                                                                            <div className="h-11 w-11 flex-shrink-0">

                                                                                {shop.favicon == null || shop.favicon == '' ?
                                                                                    <div
                                                                                        className="h-12 w-12 flex items-center justify-center rounded-lg bg-white object-cover ring-1 ring-gray-900/10 fade-in">
                                                                                        <BuildingStorefrontIcon className='text-gray-400 group-hover:text-emerald-600 h-6 w-6 shrink-0'/>
                                                                                    </div>
                                                                                : 
                                                                                    <img
                                                                                        src={shop.favicon}
                                                                                        className="h-12 w-12 p-2 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"/>
                                                                                }
                                                                                
                                                                            </div>
                                                                            <div className="ml-4">
                                                                                <div className="text-sm font-medium text-gray-900">{shop.name}</div>
                                                                                <div className="mt-1 text-sm text-gray-500">{shop.domain}</div>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </td>
                                                                <td className="whitespace-nowrap text-sm text-gray-500">
                                                                    <Link to={`/shop?id=${shop._id}`}>  
                                                                        <div className="px-3">
                                                                            <div className="text-sm font-medium text-gray-900">{shop.total_revenue_today ?? 0}</div>
                                                                            <div className="mt-1 text-sm text-gray-500">{shop.total_orders_today ?? 0}</div>
                                                                        </div>
                                                                    </Link>
                                                                </td>
                                                                <td className="whitespace-nowrap text-sm text-gray-500">
                                                                    <Link to={`/shop?id=${shop._id}`}>  
                                                                        <div className="px-3">
                                                                            <div className="text-sm font-medium text-gray-900">{shop.total_revenue_yesterday ?? 0}</div>
                                                                            <div className="mt-1 text-sm text-gray-500">{shop.total_orders_yesterday ?? 0}</div>
                                                                        </div>
                                                                    </Link>
                                                                </td>
                                                                <td className="whitespace-nowrap text-sm text-gray-500">
                                                                    <Link to={`/shop?id=${shop._id}`}>  
                                                                        <div className="px-3">
                                                                            <div className="text-sm font-medium text-gray-900">{shop.total_revenue_last_7_days ?? 0}</div>
                                                                            <div className="mt-1 text-sm text-gray-500">{shop.total_orders_last_7_days ?? 0}</div>
                                                                        </div>
                                                                    </Link>
                                                                </td>
                                                                <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium">
                                                                    <Link to={`/shop?id=${shop._id}`}> 

                                                                        <div className='flex items-center justify-end px-3'>
                                                                            <button 
                                                                                disabled={this.state.removingStore} 
                                                                                onClick={(event) => this.removeStore(event, shop._id)} 
                                                                                type="submit"
                                                                                className='mr-3 fade-in flex items-center rounded-md px-3.5 py-2.5 text-sm font-semibold text-red-400 border-red shadow-sm ring-1 ring-inset ring-red-400'>
                                                                                    
                                                                                    {this.state.removingStore && this.state.removingStoreId == shop.id ?
                                                                                        <div className='pr-2'>
                                                                                            <ArrowPathIcon className="spin h-4 w-4 [&>path]:stroke-[3]" />
                                                                                        </div>
                                                                                    : null}

                                                                                    <div className='ml-auto'>
                                                                                        Remove
                                                                                    </div>
                                                                            </button>
                                                                            <div className='ml-3'>
                                                                                <button 
                                                                                    to={`/shop?id=${shop._id}`}
                                                                                    type="button"
                                                                                    className='text-emerald-600 hover:text-emerald-900 flex items-center justify-end'>
                                                                                        See details
                                                                                        <ArrowRightIcon className="h-4 w-4 ml-2 [&>path]:stroke-[3]" />
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : null}

                        </div>

                    </div>

                </main>

            </>
        )
    }
});