// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyBgGrM5ZC35beHMNleKTdH46nFaALrTKFs",
	authDomain: 'app.trackvision.io',
	// authDomain: "trackvision-io.firebaseapp.com",
	projectId: "trackvision-io",
	storageBucket: "trackvision-io.appspot.com",
	messagingSenderId: "316599325202",
	appId: "1:316599325202:web:9adf1a6c2af2dfc47bbecb",
	measurementId: "G-25K36W0SKS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const appCheck = initializeAppCheck(app, {
// 	provider: new ReCaptchaV3Provider('6LdCACAoAAAAAHMDQAUlQ1Z5TH7UhX5U1VYu-GoK'),
  
// 	// Optional argument. If true, the SDK automatically refreshes App Check
// 	// tokens as needed.
// 	isTokenAutoRefreshEnabled: true
// });

// Initialize Firebase Authentication and get a reference to the service.
export const auth = getAuth(app);

// Initialize Firebase db and get a reference to the service.
export const db = getFirestore(app);

export default app;

