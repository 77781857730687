import { db } from '../firebase';
import { collection, getDocs, getDoc, onSnapshot, query, setDoc, where, doc, addDoc, and, deleteDoc } from "firebase/firestore";
import ApiService from './apiservice';
import WebService from './webservice';

const DBService = {

    userDetails(userId) {
		return new Promise(async (resolve, reject) => {

            // Get user details.
            const userDetailsRef = doc(db, 'userDetails', userId);

            const docSnap = await getDoc(userDetailsRef);

            resolve(docSnap.data());

		});
	},

    saveUserDetails(userDetails, userId) {
		return new Promise(async (resolve, reject) => {

            // Get user details.
            const userDetailsRef = doc(db, 'userDetails', userId);

            // Set the user details.
            await setDoc(userDetailsRef, userDetails);

            resolve();

		});
	},

    sendToCheckout(userId, priceId) {
		return new Promise(async (resolve, reject) => {
            
            // GET CUSTOMER.
            const customerRef = doc(db, 'customers', userId);

            // ADD CHECKOUT SESSION
            const checkoutSessionsCollection = collection(customerRef, 'checkout_sessions');
            const checkoutRef = await addDoc(checkoutSessionsCollection, { 
                price: priceId,
                success_url: window.location.origin,
                cancel_url: window.location.origin,
                trial_from_plan: true
                // promotion_code: WebService.promoCode
            });

            const unsubscribe = onSnapshot(checkoutRef, (docSnapshot) => {
                if (docSnapshot.exists()) {

                    let data = docSnapshot.data();

                    if (data.sessionId) {

                        unsubscribe();
                        resolve(data.sessionId)
                    }
 
        
                } else {

                    console.log('Document not found');
                    unsubscribe();
                    
                }
            });
		});
	},

    prices() {
        return new Promise(async (resolve, reject) => {

            // Create the query.
            const q = query(collection(db, "products"), where("active", "==", true));

            // Execute the query.
            const querySnapshot = await getDocs(q);
            
            // Build the subscriptions
            let subscriptions = [];
            // querySnapshot.forEach((doc) => {
            //     let data = doc.data();
            //     // const pricesCollection = collection(doc.ref, 'price');
            //     // const q = query(pricesCollection); //, where("status", "in", ['trialing', 'active']));
            //     // const querySnapshot = await getDocs(q);
            //     subscriptions.push(data)
            // });

            for (let i = 0; i < querySnapshot.docs.length; i++) {

                // Get product.
                let data = querySnapshot.docs[i].data();

                // Get prices
                const pricesCollection = collection(querySnapshot.docs[i].ref, 'prices');
                const q = query(pricesCollection);
                const querySnapshot1 = await getDocs(q);
                let prices = [];
                querySnapshot1.forEach(doc => {
                    prices.push(doc.data());
                });
                data.prices = prices;

                subscriptions.push(data);
            }

            resolve(subscriptions);
        });
    },

    subscriptions(userId) {
        return new Promise(async (resolve, reject) => {

            const customerRef = doc(db, 'customers', userId);

            const subscriptionsCollection = collection(customerRef, 'subscriptions');

            // Create the query.
            const q = query(subscriptionsCollection, where("status", "in", ['trialing', 'active']));

            // Execute the query.
            const querySnapshot = await getDocs(q);

            // Build the subscription.
            let subscriptions = [];
            querySnapshot.forEach((doc) => {
                subscriptions.push(doc.data()); 
            });

            resolve(subscriptions)

        });
    },

    products(userId) {
        return new Promise(async (resolve, reject) => {

            // Create the query.
            const q = query(collection(db, "trackedProducts"), where("userId", "==", userId));

            // Execute the query.
            const querySnapshot = await getDocs(q);

            let products = [];
            for (let i = 0; i < querySnapshot.docs.length; i++) {

                // Add the product.
                products.push({
                    id: querySnapshot.docs[i]._document.data.value.mapValue.fields.productId.integerValue,
                    shopId: querySnapshot.docs[i]._document.data.value.mapValue.fields.shopId.stringValue,
                    userId: querySnapshot.docs[i]._document.data.value.mapValue.fields.userId.stringValue,
                    createdAt: querySnapshot.docs[i]._document.data.value.mapValue.fields.productId.timestampValue
                });
            }

            resolve(products);
        });
    },

    /**
     * Get the shops with details.
     * @param {*} userId 
     * @returns 
     */
    shops(userId) {
        return new Promise(async (resolve, reject) => {

            // Create the query.
            const q = query(collection(db, "shops"), where("userId", "==", userId));

            // Execute the query.
            const querySnapshot = await getDocs(q);

            let shops = [];
            for (let i = 0; i < querySnapshot.docs.length; i++) {
                shops.push({
                    id: querySnapshot.docs[i]._document.data.value.mapValue.fields.shopId.stringValue
                })
            }

            resolve(shops);
        });
    },

    trackByUserCount(shopId) {
        return new Promise(async (resolve, reject) => {

            // Create the query.
            const q = query(collection(db, "shops"), where("shopId", "==",  shopId));

            // Execute the query.
            const querySnapshot = await getDocs(q);

            resolve(querySnapshot.docs.length);
        });
    },

    /**
     * Adds the shop.
     * @param {*} shop the shop.
     */
    addShop(shop) {
        return new Promise(async (resolve, reject) => {

            // Add the shop to the db.
            await addDoc(collection(db, "shops"), shop);

            resolve();
            
        });
    },

    /**
     * Adds the product.
     * @param {*} product the product.
     */
    addProduct(product) {
        return new Promise(async (resolve, reject) => {

            // Add the product to the db.
            await addDoc(collection(db, "trackedProducts"), product);

            resolve();
            
        });
    },

    /**
     * Removes the product.
     * @param {*} productId the product id.
     * @param {*} userId the user id.
     */
    removeProduct(userId, productId) {
        return new Promise(async (resolve, reject) => {

            // Create the query.
            const q = query(collection(db, "trackedProducts"), where("productId", "==",  Number(productId)), where("userId", "==",  userId));

            // Execute the query.
            const querySnapshot = await getDocs(q);

            // Delete the products.
            for (let i = 0; i < querySnapshot.docs.length; i++) {
                await deleteDoc(querySnapshot.docs[i].ref);
            }

            resolve();
            
        });
    },

    /**
     * Removes the store.
     * @param {*} shopId the shop id.
     * @param {*} userId the user id.
     */
    removeStore(userId, shopId) {
        return new Promise(async (resolve, reject) => {

            // Create the query.
            const q = query(collection(db, "shops"), where("shopId", "==",  shopId), where("userId", "==",  userId));

            // Execute the query.
            const querySnapshot = await getDocs(q);
            
            // Delete the products.
            for (let i = 0; i < querySnapshot.docs.length; i++) { 
                await deleteDoc(querySnapshot.docs[i].ref);
            }

            resolve();
            
        });
    }

};

export default DBService;