import React, { Fragment } from 'react';
import { withRouter } from './withRouter';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { PlusIcon, ArrowPathIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-toastify';
import WebService from '../services/webservice';
import Limit from './limit';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import ReactDatetimeClass from 'react-datetime';

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export default withRouter(class DateBetweenInput extends React.Component {

    /**
     * Constructor.
     */
    constructor() {
        super();

        // Initial state.
        this.state = {
            toDate: null,
            fromDate: null
        };

        // // Bind functions.
        // this.addShop = this.addShop.bind(this);
        // this.checkUrl = this.checkUrl.bind(this);
    };

    /**
     * The component did mount.
     */
    componentDidMount() {

        // Set the to and from date from the props.
        this.setState({
            toDate: this.props.toDate,
            fromDate: this.props.fromDate
        });
    };

    /**
     * Render.
     */
    render() {
        return (
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    
                        <div className='flex items-center'>

                            {/* Date picker icon label */}
                            <div className='mr-2'>
                                <svg className="w-6 h-6 text-gray-400 group-hover:text-emerald-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>
                            </div>

                            {/* From date label */}
                            <span className='text-gray-700 text-sm mr-1'>
                                {monthNames[(this.props.dateFrom.getMonth())] + ' ' + this.props.dateFrom.getDate()}
                            </span>

                            <span className='text-gray-700 text-sm mr-1'>
                                -
                            </span>

                            {/* To date label */}
                            <span className='text-gray-700 text-sm mr-1'>
                                {monthNames[(this.props.dateTo.getMonth())] + ' ' + this.props.dateTo.getDate()}
                            </span>

                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />

                        </div> 

                    </Menu.Button>
                </div>
        
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">

                        <Menu.Items className="absolute sm:right-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                                <Menu.Item>
                                    {({ close }) => (
                                        <div className='p-4' onClick={(event) => {event.stopPropagation(); event.preventDefault()}}>
                                            <div className='sm:flex items-center'>
                                                <div>
                                                    <ReactDatetimeClass
                                                        className='border-0'
                                                        timeFormat={false}
                                                        dateFormat="DD/MM/YYYY"
                                                        value={this.state.dateFrom}
                                                        input={false}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                dateFrom: value.toDate == null ? this.state.dateFrom : value.toDate()
                                                            });
                                                        }} />
                                                </div>
                                                <div className='p-4'>
                                                    <ReactDatetimeClass
                                                        className='border-0'
                                                        timeFormat={false}
                                                        dateFormat="DD/MM/YYYY"
                                                        value={this.state.dateTo}
                                                        input={false}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                dateTo: value.toDate == null ? this.state.dateTo : value.toDate()
                                                            });
                                                        }} />
                                                </div>
                                            </div>
                                            <div className='flex justify-end'>
                                                <button onClick={() => {

                                                    close();
                                                    
                                                }} className='shadow-sm focus-visible:outline-emerald-500 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-5 mr-3'>
                                                    Cancel
                                                </button>
                                                <button onClick={() => {

                                                    this.props.onChange({
                                                        dateFrom: this.state.dateFrom,
                                                        dateTo: this.state.dateTo,
                                                    });

                                                    close();

                                                }} className='bg-emerald-500 text-white shadow-sm hover:bg-emerald-400 focus-visible:outline-emerald-500 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>

                </Transition>

            </Menu>
             
        );
    };
});