import React from 'react';
import { withRouter } from '../components/withRouter';
import Sidebar from '../components/sidebar';
import { ShoppingBagIcon } from '@heroicons/react/24/solid';
import { ArrowLeftIcon, ArrowPathIcon, ArrowRightIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Header from '../components/header';
import { Link } from 'react-router-dom';
import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from 'highcharts'
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { db } from '../firebase';
import { collection, getDocs, query, setDoc, where, doc, addDoc } from "firebase/firestore";
import ApiService from '../services/apiservice';
import WebService from '../services/webservice';
import { ToastContainer, toast } from 'react-toastify';
import Limit from '../components/limit';

export default withRouter(class ProductPage extends React.Component {

    /**
     * Constructor.
     */
    constructor() {
        super();

        // Get month dates.
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        // Initial state.
        this.state = {
            loadingProduct: true,
            loadingProductStats: true,
            showLimit: false
            // id: null,
            // name: null,
            // domain: null,
            // trackByUserCount: null,
            // theme: null,
            // country: null,
            // productsCount: null,
            // favicon: null,
            // orders: null,
            // revenue: null,
            // aov: null,
            // topProducts: [],
            // dateFrom: firstDay,
            // dateTo: lastDay,
            // addingProduct: false,
            // addingProductId: null,
            // loadingUserProdcuts: false,
            // userProducts: []
        };

        // Bind funcitons.
        this.classNames = this.classNames.bind(this);
        this.getShop = this.getShop.bind(this);
        this.getShopStats = this.getShopStats.bind(this);
        this.trackProduct = this.trackProduct.bind(this);
        this.getUserProducts = this.getUserProducts.bind(this);
        this.removeStore = this.removeStore.bind(this);

        // Set title
        document.title = 'Product - Track Vision';
    };

    /**
     * Component did mount.
     */
    componentDidMount() {

        // Auth observer.
        this.authUnsubscribe = onAuthStateChanged(auth, user => {
            if (user) {

                // Check if the user has an active subscription.
                WebService.userDetails(user).then(userDetails => {
                    if(!userDetails.hasActiveSubscription) {
                        this.props.navigate('/settings?tab=plan&showSelectAPlan=true');
                    }
                    this.setState({
                        userDetails: userDetails
                    });
                }, error => {
                    toast(error);
                });

                // Set the user.
                this.setState({
                    user: user
                }, () => {

                    // // Get the shop.
                    // this.getShop();

                    // // Get the shop stats.
                    // this.getShopStats();

                    // // Get the users products.
                    // this.getUserProducts();

                });
            }
        });
    };

    /**
     * Component will unmount.
     */
    componentWillUnmount() {

        // Unsubscribe the auth observer.
        this.authUnsubscribe();
    };

    /**
     * 
     * @param  {...any} classes 
     * @returns 
     */
    classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    };

    /**
     * Gets the shop.
     */
    getShop() {
        
        // Set loading.
        this.setState({
            loadingProduct: true,
        });

        // Get the shop id.
        const shopId = new URLSearchParams(window.location.search).get('id');

        // Get the shop.
        WebService.storePage(shopId, '2000-01-01', '2050-01-01').then(shopWithDetails => {

            // Currency formatter.
            let dollar = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            });

            // Set the shop details.
            this.setState({
                loadingProduct: false,
                id: shopWithDetails.id,
                name: shopWithDetails.name,
                domain: shopWithDetails.url,
                theme: shopWithDetails.theme,
                country: shopWithDetails.country,
                productsCount: shopWithDetails.product_count.toLocaleString(),
                favicon: shopWithDetails.favicon,
                orders: shopWithDetails.total_orders.toLocaleString(),
                revenue: dollar.format(shopWithDetails.total_revenue),
                aov: shopWithDetails.total_orders > 0 ? dollar.format((shopWithDetails.total_revenue / shopWithDetails.total_orders)) : '$0.00',
                topProducts: shopWithDetails.top_products
            });

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                loadingProduct: false,
            });

        });
    };

    /**
     * Gets the shop stats.
     */
    getShopStats() {
        
        // Set loading.
        this.setState({
            loadingProductStats: true,
        });

        // Get the shop id.
        const shopId = new URLSearchParams(window.location.search).get('id');

        // Get start and end of month.
        function ensureTwoChars(original) {
            if(original.length < 2) {
                return '0' + original;
            }
            return original;
        };

        // Get the from and to date strings.
        const fromString = `${ensureTwoChars(this.state.dateFrom.getFullYear().toString())}-${ensureTwoChars((this.state.dateFrom.getMonth() + 1).toString())}-${ensureTwoChars(this.state.dateFrom.getDate().toString())}`;
        const toString = `${ensureTwoChars(this.state.dateTo.getFullYear().toString())}-${(ensureTwoChars(this.state.dateTo.getMonth() + 1).toString())}-${ensureTwoChars(this.state.dateTo.getDate().toString())}`;
        
        // Get the shop.
        WebService.shopStats(shopId, fromString, toString).then(shopStats => {

            // Set shop in state.
            this.setState({
                loadingProductStats: false,
                trackByUserCount: shopStats.trackByUserCount,
                customTotalAmount: shopStats.customAmount,
                customTotalCount: shopStats.customCount,
                customRevenueData: shopStats.customRevenueData,
                customCountData: shopStats.customCountData
            });

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                loadingProductStats: false,
            });

        });

    };

    /**
     * Gets the users tracked products.
     */
    getUserProducts() {

        // Set loading.
        this.setState({
            loadingUserProdcuts: true,
        });

        // Get the shop.
        WebService.userProducts(this.state.user.uid).then(userProducts => {

            // Set shop in state.
            this.setState({
                loadingUserProdcuts: false,
                userProducts: userProducts,
            });

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                loadingProductStats: false,
            });

        });
    };

    /**
     * Tracks a product.
     * @param {*} productId the product id. 
     */
    trackProduct(productId) {

        // Prevent while loading.
        if (this.state.addingProduct) {
            return;
        }

        // Determine if we're deleting or not.
        const adding = this.state.userProducts.map(userProduct => (userProduct.id.toString()) ).indexOf(productId.toString()) == -1;

        // Get the store id.
        const shopId = new URLSearchParams(window.location.search).get('id');

        // Check limits
        if (adding && this.state.userProducts.length > this.state.userDetails.productLimit - 1 ) {
            this.setState({
                showLimit: true
            })
            return;
        }

        // Set loading.
        this.setState({
            addingProduct: true,
            addingProductId: productId
        });

        if (adding) {

            // Add the product.
            WebService.addProduct(productId, this.state.user?.uid, shopId).then(response => {

                // Toast.
                toast('Product added!');

                // Update the product buttons.
                this.getUserProducts();

                // Update loaders.
                this.setState({
                    addingProduct: false,
                    addingProductId: null,
                });

            }, error => {

                // Show the error.
                toast(error);

                // Update loaders.
                this.setState({
                    addingProduct: false,
                    addingProductId: null,
                });

            });

        } else {

            // Remove the product.
            WebService.removeProduct(productId, this.state.user?.uid).then(response => {

                // Toast.
                toast('Product removed!');

                // Update the product buttons.
                this.getUserProducts();

                // Update loaders.
                this.setState({
                    addingProduct: false,
                    addingProductId: null,
                });

            }, error => {

                // Show the error.
                toast(error);

                // Update loaders.
                this.setState({
                    addingProduct: false,
                    addingProductId: null,
                });

            });

        }
    };

    /**
     * Removes the store.
     */
    removeStore() {

        // Prevent while loading.
        if (this.state.removingStore) {
            return;
        }

        // Set loading.
        this.setState({
            removingStore: true
        });

        // Get the shop id.
        const shopId = new URLSearchParams(window.location.search).get('id');

        // Remove the product.
        WebService.removeStore(shopId, this.state.user?.uid).then(response => {

            // Toast.
            toast('Store removed!');

            // Back to shops.
            window.location.href = '/shops';

            // Update loaders.
            this.setState({
                removingStore: false
            });

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                removingStore: false
            });

        });

    };

    /**
     * 
     */
    render() {
        return (
            <>

                {/* Limit */}
                {this.state.showLimit ? 
                  <Limit
                    onDismiss={() => 
                        this.setState({
                            showLimit: false
                        })
                    }/>
                : null}

                {/* Toast */}
                <ToastContainer autoClose={3000} theme='dark'/>

                {/* Sidebar */}
                <Sidebar
                    title='Tracked Products'
                    userDetails={this.state.userDetails}/>

                {/* Content */}
                <main className="lg:pl-72">

                    {/* Header */}
                    <Header
                        title='Your Tracked Products'
                        subtitle='Tracked Products'
                        icon={<ShoppingBagIcon className='h-8 w-8 mb-1'/>}
                        userDetails={this.state.userDetails}/>

                    {/* Main */}
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">

                        {/* Back button */}
                        <div className="pb-5">
                            <Link to={'/products'} className="text-sm font-semibold text-emerald-600 hover:text-emerald-900 flex items-center">
                                <ArrowLeftIcon className="h-4 w-4 mr-2 [&>path]:stroke-[3]" />
                                <div>All tracked products</div>
                            </Link>
                        </div>

                        <div className="rounded-lg shadow-sm ring-1 ring-gray-900/5 mb-8">
                            <div className="flex items-center border-b border-gray-900/5 p-6">

                                {this.state.loadingProduct ?
                                    <div className='loading w-full h-14'></div>
                                :
                                    <>
                                            {/* Logo */}
                                        <img
                                            src={this.state.favicon}
                                            className="h-14 w-14 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10 fade-in"/>

                                        {/* Name and domain */}
                                        <div className='ml-4 fade-in'>
                                            <div className="text-base font-semibold text-gray-900 mb-1">{this.state.name}</div>
                                            <div className="text-sm text-gray-500">{this.state.domain}</div>
                                        </div>
                                    </>
                                }
            
                            </div>
                            <div className='px-4 py-5 sm:p-6 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-5 gap-8'>

                                {/* Tracked by */}
                                <div>
                                    {this.state.loadingProduct ?
                                        <div className='loading w-full h-12'></div>
                                    :
                                        <>
                                            <dt className="fade-in text-sm text-gray-500">Tracked by</dt>
                                            <dd className="fade-in text-base font-medium text-gray-900 mt-1">
                                                {this.state.trackByUserCount} Users
                                            </dd>
                                        </>
                                    }
                                </div>

                                {/* Theme */}
                                <div>
                                    {this.state.loadingProduct ?
                                        <div className='loading w-full h-12'></div>
                                    :
                                        <>
                                            <dt className="fade-in text-sm text-gray-500">Theme</dt>
                                            <dd className="fade-in text-base font-medium text-gray-900 mt-1">
                                                {this.state.theme}
                                            </dd>
                                        </>
                                    }
                                </div>

                                {/* Country */}
                                <div>
                                    {this.state.loadingProduct ?
                                        <div className='loading w-full h-12'></div>
                                    :
                                        <>
                                            <dt className="fade-in text-sm text-gray-500">Country</dt>
                                            <dd className="fade-in text-base font-medium text-gray-900 mt-1">
                                                {this.state.country}
                                            </dd>
                                        </>
                                    }
                                </div>

                                {/* Products count */}
                                <div>
                                    {this.state.loadingProduct ?
                                        <div className='loading w-full h-12'></div>
                                    :
                                        <>
                                            <dt className="fade-in text-sm text-gray-500">Products</dt>
                                            <dd className="fade-in text-base font-medium text-gray-900 mt-1">
                                                {this.state.productsCount}
                                            </dd>
                                        </>
                                    }
                                </div>

                                {/* Remove */}
                                <div>
                                    {this.state.loadingProduct ?
                                            <div className='loading w-full h-12'></div>
                                        :
                                            <>
                                                <button 
                                                    disabled={this.state.removingStore} 
                                                    onClick={() => this.removeStore()} 
                                                    type="submit"
                                                    className='fade-in flex items-center rounded-md bg-red-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-400'>
                                                        
                                                        {this.state.removingStore ?
                                                            <div className='pr-2'>
                                                                <ArrowPathIcon className="spin h-4 w-4 [&>path]:stroke-[3]" />
                                                            </div>
                                                        : null}

                                                        <div className='ml-auto'>
                                                            Remove
                                                        </div>     
                                                </button>
                                            </>
                                        }
                                </div>

                            </div>
                        </div>

                        <div className="relative rounded-lg shadow-sm ring-1 ring-gray-900/5 mb-8 overflow-hidden">

                            {/* Stats */}
                            <div>
                                <dl className="mx-auto grid max-w-7xl grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">

                                    {/* Total Revenue */}
                                    <div className='sm:border-l lg:border-t-0 border-t flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-gray-900/5 px-4 py-10 sm:px-6 xl:px-8'>
                                        {this.state.loadingProduct ?
                                            <div className='loading w-full' style={{height: '5.8rem'}}></div>
                                        :
                                            <>
                                                <dt className="fade-in text-sm font-medium text-gray-500">Total Revenue</dt>
                                                <dd className="fade-in w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                                                    {this.state.revenue}
                                                </dd>
                                            </>
                                        }   
                                    </div>

                                    {/* Total Sales */}
                                    <div className='lg:border-l lg:border-t-0 border-t flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-gray-900/5 px-4 py-10 sm:px-6 xl:px-8'>
                                        {this.state.loadingProduct ?
                                            <div className='loading w-full' style={{height: '5.8rem'}}></div>
                                        :
                                            <>
                                                <dt className="fade-in text-sm font-medium text-gray-500">Total Sales</dt>
                                                <dd className="fade-in w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                                                    {this.state.orders}
                                                </dd>
                                            </>
                                        }   
                                    </div>

                                    {/* AOV */}
                                    <div className='sm:border-l lg:border-t-0 border-t flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-gray-900/5 px-4 py-10 sm:px-6 xl:px-8'>
                                        {this.state.loadingProduct ?
                                            <div className='loading w-full' style={{height: '5.8rem'}}></div>
                                        :
                                            <>
                                                <dt className="fade-in text-sm font-medium text-gray-500">AOV</dt>
                                                <dd className="fade-in w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                                                    {this.state.aov}
                                                </dd>
                                            </>
                                        } 
                                    </div>

                                    {/* Products */}
                                    <div className='lg:border-l lg:border-t-0 border-t flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-gray-900/5 px-4 py-10 sm:px-6 xl:px-8'>
                                        {this.state.loadingProduct ?
                                            <div className='loading w-full' style={{height: '5.8rem'}}></div>
                                        :
                                            <>
                                                <dt className="fade-in text-sm font-medium text-gray-500">Products</dt>
                                                <dd className="fade-in w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                                                    {this.state.productsCount}
                                                </dd>
                                            </>
                                        } 
                                    </div>

                                </dl>
                            </div>

                            {/* Effect */}
                            <div
                                className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
                                aria-hidden="true">
                                <div
                                    className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#10b981] to-[#4ade80]"
                                    style={{
                                        clipPath:
                                        'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                                    }}/>
                            </div>

                        </div>

                        {/* Revenue Chart */}
                        <div className="p-6 rounded-lg shadow-sm ring-1 ring-gray-900/5 mb-8">
                            <div className='flex justify-between'>
                                {this.state.loadingProduct ?
                                    <div className='loading w-full h-14'></div>
                                :
                                    <>
                                        {/* Title */}
                                        <div>
                                            <div className="fade-in text-sm text-gray-500">Total Revenue</div>
                                            <div className="fade-in text-base font-medium text-gray-900 mt-1">{this.state.customTotalAmount}</div>
                                        </div>

                                        {/* Date */}
                                        <div className="fade-in text-sm text-gray-500">{this.state.dateFrom.toLocaleDateString()} - {this.state.dateTo.toLocaleDateString()}</div>
                                    </>
                                }
                            </div>

                            {/* Chart */}
                            <div className='pt-16'>  
                                {this.state.loadingProduct ?
                                    <div className='loading w-full' style={{height: '26.5rem'}}></div>
                                :
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={{
                                            chart: {
                                                type: 'area'
                                            },
                                            title: {
                                                text: ''
                                            },
                                            plotOptions: {
                                                series: {
                                                    fillColor: {
                                                        linearGradient: [0, 0, 0, 300],
                                                        stops: [
                                                            [0, '#d1fae5'],
                                                            [1, '#ffffff']
                                                        ]
                                                    }
                                                }
                                            },
                                            xAxis: {
                                                type: 'datetime',
                                                lineColor: '#e6e6e6',
                                                labels: {
                                                    style: {
                                                        color: '#6b7280',
                                                    }
                                                }
                                            },
                                            yAxis: {
                                                lineColor: '#e6e6e6',
                                                labels: {
                                                    style: {
                                                        color: '#6b7280',
                                                    }
                                                }
                                            },
                                            series: [{
                                                name: 'Revenue',
                                                color: '#10b981',
                                                type: 'area',
                                                data: this.state.customRevenueData
                                            }]
                                        }}/>
                                    }
                            </div>
                        </div>

                        {/* Sales Chart */}
                        <div className="p-6 rounded-lg shadow-sm ring-1 ring-gray-900/5 mb-8">
                            <div className='flex justify-between'>
                                {this.state.loadingProduct ?
                                    <div className='loading w-full h-14'></div>
                                :
                                    <>
                                        {/* Title */}
                                        <div>
                                            <div className="fade-in text-sm text-gray-500">Total Sales</div>
                                            <div className="fade-in text-base font-medium text-gray-900 mt-1">{this.state.customTotalCount}</div>
                                        </div>

                                        {/* Date */}
                                        <div className="fade-in text-sm text-gray-500">{this.state.dateFrom.toLocaleDateString()} - {this.state.dateTo.toLocaleDateString()}</div>
                                    </>
                                }
                            </div>

                            {/* Chart */}
                            <div className='pt-16'>    
                                {this.state.loadingProduct ?
                                    <div className='loading w-full' style={{height: '26.5rem'}}></div>
                                :             
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={{
                                            chart: {
                                                type: 'area'
                                            },
                                            title: {
                                                text: ''
                                            },
                                            plotOptions: {
                                                series: {
                                                    fillColor: {
                                                        linearGradient: [0, 0, 0, 300],
                                                        stops: [
                                                            [0, '#d1fae5'],
                                                            [1, '#ffffff']
                                                        ]
                                                    }
                                                }
                                            },
                                            xAxis: {
                                                type: 'datetime',
                                                lineColor: '#e6e6e6',
                                                labels: {
                                                    style: {
                                                        color: '#6b7280',
                                                    }
                                                }
                                            },
                                            yAxis: {
                                                lineColor: '#e6e6e6',
                                                labels: {
                                                    style: {
                                                        color: '#6b7280',
                                                    }
                                                }
                                            },
                                            series: [{
                                                name: 'Revenue',
                                                color: '#10b981',
                                                type: 'area',
                                                data: this.state.customCountData
                                            }]
                                        }}/>
                                    }
                            </div>
                        </div>

                    </div>

                </main>

            </>
        )
    }

    // /**
    //  * Constructor.
    //  */
    // constructor() {
    //     super();

    //     // Initial state.
    //     this.state = {
    //         shop: 
    //         {  
    //             id: '62219518291',     
    //             // name: 'Baills',
    //             // domain: 'baills.com',
    //             // salesTodayCount: '10',
    //             // salesTodayAmount: '$100',
    //             // salesYesterdayCount: '20',
    //             // salesYesterdayAmount: '$200',
    //             // salesWeekCount: '30',
    //             // salesWeekAmount: '$300',
    //             img_url: 'https://cdn.shopify.com/s/files/1/0021/1930/3203/files/compressionshopify.jpg?v=1692038142',

    //             // id: '62219518291',     
    //             // name: 'Baills',
    //             // domain: 'baills.com',
    //             createdDate: '1st Jan 2023',
    //             trackByUserCount: '0 users',
    //             theme: 'Broadcast',
    //             country: 'US',
    //             productsCount: '0',
    //             // favicon: 'https://ohsnap.com/cdn/shop/files/favicon_0777975c-e8e4-4f61-a024-bd4abcb40063.png?crop=center&height=32&v=1617823497&width=32' 
    //         },
    //         products: [
    //             {
    //                 id: '8379196375308',
    //                 salesTodayCount: '10',
    //                 salesTodayAmount: '$100',
    //                 salesYesterdayCount: '20',
    //                 salesYesterdayAmount: '$200',
    //                 salesWeekCount: '30',
    //                 salesWeekAmount: '$300',
    //                 img_url: 'https://cdn.shopify.com/s/files/1/0550/6947/4895/files/Charge-Pro-Apple.png?v=1686825560'
    //             },
    //             {
    //                 id: '8379196375308',
    //                 salesTodayCount: '10',
    //                 salesTodayAmount: '$100',
    //                 salesYesterdayCount: '20',
    //                 salesYesterdayAmount: '$200',
    //                 salesWeekCount: '30',
    //                 salesWeekAmount: '$300',
    //                 img_url: 'https://cdn.shopify.com/s/files/1/0550/6947/4895/files/Charge-Pro-USB-C.png?v=1686825561'
    //             }
    //         ],
    //         stats: [
    //             { name: 'Total Revenue', value: '$0', change: '+4.75%', changeType: 'positive' },
    //             { name: 'Total Sales', value: '0', change: '+54.02%', changeType: 'negative' },
    //             { name: 'AOV', value: '$0', change: '-1.39%', changeType: 'positive' },
    //             { name: 'Products', value: '0', change: '+10.18%', changeType: 'negative' },
    //         ]
    //     };

    //     // Bind funcitons.
    //     this.classNames = this.classNames.bind(this);
    // };

    // /**
    //  * Component did mount.
    //  */
    // componentDidMount() {

    // };

    // /**
    //  * 
    //  * @param  {...any} classes 
    //  * @returns 
    //  */
    // classNames(...classes) {
    //     return classes.filter(Boolean).join(' ')
    // };

    /**
     * 
     */
    // render() {
    //     return (
    //         <>
            
    //         </>
            
            // <>

            //     {/* Sidebar */}
            //     <Sidebar
            //         title='Tracked Shops'/>

            //     {/* Content */}
            //     <main className="lg:pl-72">

            //         {/* Header */}
            //         <Header
            //             title='Your Tracked Shops'
            //             subtitle='Tracked Shops'
            //             icon={<BuildingStorefrontIcon className='h-8 w-8 mb-1'/>}/>

            //         {/* Main */}
            //         <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">

            //             {/* Back button */}
            //             <div className="pb-5">
            //                 <Link to={'/shops'} className="text-sm font-semibold text-emerald-600 hover:text-emerald-900 flex items-center">
            //                     <ArrowLeftIcon className="h-4 w-4 mr-2 [&>path]:stroke-[3]" />
            //                     <div>All tracked shops</div>
            //                 </Link>
            //             </div>

            //             <div className="rounded-lg shadow-sm ring-1 ring-gray-900/5 mb-8">
            //                 <div className="flex items-center border-b border-gray-900/5 p-6">

            //                     {/* Logo */}
            //                     <img
            //                         src={'https://cdn.shopify.com/s/files/1/0021/1930/3203/files/compressionshopify.jpg?v=1692038142' }
            //                         className="h-14 w-14 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"/>

            //                     {/* Name and domain */}
            //                     <div className='ml-4'>
            //                         {/* class="text-base font-semibold leading-6 text-gray-900" */}
            //                             {/* <div className="text-base font-semibold text-gray-900 mb-1">Ballis</div>
            //                             <div className="text-sm text-gray-500">Issued on</div> */}
            //                     </div>
            
            //                 </div>
            //                 <div className='px-4 py-5 sm:p-6 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-5 gap-8'>

            //                     {/* Tracked by */}
            //                     <div>
            //                         <dt className="text-sm text-gray-500">Tracked by</dt>
            //                         <dd className="text-base font-medium text-gray-900 mt-1">
            //                             {this.state.shop.trackByUserCount}
            //                         </dd>
            //                     </div>

            //                     {/* Creation date */}
            //                     <div>
            //                         <dt className="text-sm text-gray-500">Creation date</dt>
            //                         <dd className="text-base font-medium text-gray-900 mt-1">
            //                             {this.state.shop.createdDate}
            //                         </dd>
            //                     </div>

            //                     {/* Theme */}
            //                     <div>
            //                         <dt className="text-sm text-gray-500">Theme</dt>
            //                         <dd className="text-base font-medium text-gray-900 mt-1">
            //                             {this.state.shop.theme}
            //                         </dd>
            //                     </div>

            //                     {/* Country */}
            //                     <div>
            //                         <dt className="text-sm text-gray-500">Country</dt>
            //                         <dd className="text-base font-medium text-gray-900 mt-1">
            //                             {this.state.shop.country}
            //                         </dd>
            //                     </div>

            //                     {/* Products count */}
            //                     <div>
            //                         <dt className="text-sm text-gray-500">Products</dt>
            //                         <dd className="text-base font-medium text-gray-900 mt-1">
            //                             {this.state.shop.productsCount}
            //                         </dd>
            //                     </div>

            //                 </div>
            //             </div>

            //             <div className="relative rounded-lg shadow-sm ring-1 ring-gray-900/5 mb-8 overflow-hidden">

            //                 {/* Stats */}
            //                 <div>
            //                     <dl className="mx-auto grid max-w-7xl grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
            //                         {this.state.stats.map((stat, statIdx) => (
            //                             <div 
            //                                 key={stat.name}
            //                                 className={this.classNames(
            //                                     statIdx % 2 === 1 ? 'sm:border-l lg:border-t-0 border-t' : statIdx === 2 ? 'lg:border-l lg:border-t-0 border-t' : '',
            //                                     'flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-gray-900/5 px-4 py-10 sm:px-6 xl:px-8'
            //                                 )}>
            //                                     <dt className="text-sm font-medium text-gray-500">{stat.name}</dt>
            //                                     <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
            //                                         {stat.value}
            //                                     </dd>
            //                             </div>
            //                         ))}
            //                     </dl>
            //                 </div>

            //                 {/* Effect */}
            //                 <div
            //                     className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
            //                     aria-hidden="true">
            //                     <div
            //                         className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#10b981] to-[#4ade80]"
            //                         style={{
            //                             clipPath:
            //                             'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
            //                         }}/>
            //                 </div>

            //             </div>

            //             {/* Revenue Chart */}
            //             <div className="p-6 rounded-lg shadow-sm ring-1 ring-gray-900/5 mb-8">
            //                 <div className='flex justify-between'>

            //                     {/* Title */}
            //                     <div>
            //                         <div className="text-sm text-gray-500">Total Revenue</div>
            //                         <div className="text-base font-medium text-gray-900 mt-1">$0</div>
            //                     </div>

            //                     {/* Date */}
            //                     <div className="text-sm text-gray-500">1st Jan 2022 - 1st Jan  2023</div>

            //                 </div>

            //                 {/* Chart */}
            //                 <div className='pt-16'>                 
            //                     <HighchartsReact
            //                         highcharts={Highcharts}
            //                         options={{
            //                             chart: {
            //                                 type: 'area'
            //                             },
            //                             title: {
            //                                 text: ''
            //                             },
            //                             plotOptions: {
            //                                 series: {
            //                                     fillColor: {
            //                                         linearGradient: [0, 0, 0, 300],
            //                                         stops: [
            //                                             [0, '#d1fae5'],
            //                                             [1, '#ffffff']
            //                                         ]
            //                                     }
            //                                 }
            //                             },
            //                             xAxis: {
            //                                 lineColor: '#e6e6e6',
            //                                 labels: {
            //                                     style: {
            //                                         color: '#6b7280',
            //                                     }
            //                                 }
            //                             },
            //                             yAxis: {
            //                                 lineColor: '#e6e6e6',
            //                                 labels: {
            //                                     style: {
            //                                         color: '#6b7280',
            //                                     }
            //                                 }
            //                             },
            //                             series: [{
            //                                 name: 'Revenue',
            //                                 color: '#10b981',
            //                                 data: [20, 200, 100, 0.1, 150, 50, 30, 40, 300]
            //                             }]
            //                         }}/>
            //                 </div>
            //             </div>

            //             {/* Sales Chart */}
            //             <div className="p-6 rounded-lg shadow-sm ring-1 ring-gray-900/5 mb-8">
            //                 <div className='flex justify-between'>

            //                     {/* Title */}
            //                     <div>
            //                         <div className="text-sm text-gray-500">Total Sales</div>
            //                         <div className="text-base font-medium text-gray-900 mt-1">0</div>
            //                     </div>

            //                     {/* Date */}
            //                     <div className="text-sm text-gray-500">1st Jan 2022 - 1st Jan  2023</div>

            //                 </div>

            //                 {/* Chart */}
            //                 <div className='pt-16'>                 
            //                     <HighchartsReact
            //                         highcharts={Highcharts}
            //                         options={{
            //                             chart: {
            //                                 type: 'area'
            //                             },
            //                             title: {
            //                                 text: ''
            //                             },
            //                             plotOptions: {
            //                                 series: {
            //                                     fillColor: {
            //                                         linearGradient: [0, 0, 0, 300],
            //                                         stops: [
            //                                             [0, '#d1fae5'],
            //                                             [1, '#ffffff']
            //                                         ]
            //                                     }
            //                                 }
            //                             },
            //                             xAxis: {
            //                                 lineColor: '#e6e6e6',
            //                                 labels: {
            //                                     style: {
            //                                         color: '#6b7280',
            //                                     }
            //                                 }
            //                             },
            //                             yAxis: {
            //                                 lineColor: '#e6e6e6',
            //                                 labels: {
            //                                     style: {
            //                                         color: '#6b7280',
            //                                     }
            //                                 }
            //                             },
            //                             series: [{
            //                                 name: 'Sales',
            //                                 color: '#10b981',
            //                                 data: [20, 200, 100, 0.1, 150, 50, 30, 40, 300]
            //                             }]
            //                         }}/>
            //                 </div>
            //             </div>

            //             {/* Products */}
            //             <div className="rounded-lg shadow-sm ring-1 ring-gray-900/5 overflow-hidden">
            //                 <div className="flow-root">
            //                     <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            //                         <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
            //                             <table className="min-w-full divide-y divide-gray-300">
            //                                 <thead>
            //                                     <tr>
            //                                         <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
            //                                             Product
            //                                         </th>
            //                                         <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            //                                             Sales Today
            //                                         </th>
            //                                         <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            //                                             Sales Yesterday
            //                                         </th>
            //                                         <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            //                                             Sales 1 Week
            //                                         </th>
            //                                         <th scope="col" className="relative py-3.5 pl-3 pr-4">
            //                                         </th>
            //                                     </tr>
            //                                 </thead>
            //                                 <tbody className="divide-y divide-gray-200 bg-white">
            //                                     {this.state.products.map((product) => (
            //                                         <Link key={product.id} className='hover:bg-slate-50' style={{ cursor: 'pointer', display: 'table-row' }} to={`/producthop?id=${product.id}`}>
            //                                             <td className="whitespace-nowrap text-sm py-4 pl-4 pr-3" style={{ verticalAlign: 'middle' }}>
            //                                                 <div className="flex items-center">
            //                                                     <div className="h-11 w-11 flex-shrink-0">
            //                                                         <img
            //                                                             src={product.img_url}
            //                                                             className="h-12 w-12 p-1 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"/>
            //                                                     </div>
            //                                                     <div className="ml-4">

            //                                                     </div>
            //                                                 </div>
            //                                             </td>
            //                                             <td className="whitespace-nowrap text-sm text-gray-500 px-3" style={{ verticalAlign: 'middle' }}>
            //                                                 <div className="text-sm font-medium text-gray-900">{product.salesTodayAmount}</div>
            //                                                 <div className="mt-1 text-sm text-gray-500">{product.salesTodayCount}</div>
            //                                             </td>
            //                                             <td className="whitespace-nowrap text-sm text-gray-500 px-3" style={{ verticalAlign: 'middle' }}>
            //                                                 <div className="text-sm font-medium text-gray-900">{product.salesYesterdayAmount}</div>
            //                                                 <div className="mt-1 text-sm text-gray-500">{product.salesYesterdayCount}</div>
            //                                             </td>
            //                                             <td className="whitespace-nowrap text-sm text-gray-500 px-3" style={{ verticalAlign: 'middle' }}>
            //                                                 <div className="text-sm font-medium text-gray-900">{product.salesWeekAmount}</div>
            //                                                 <div className="mt-1 text-sm text-gray-500">{product.salesWeekCount}</div>
            //                                             </td>
            //                                             <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium" style={{ verticalAlign: 'middle' }}>
            //                                                 <Link 
            //                                                     to={`/producthop?id=${product.id}`}
            //                                                     type="button"
            //                                                     className='text-emerald-600 hover:text-emerald-900 flex items-center justify-end'>
            //                                                         See details
            //                                                         <ArrowRightIcon className="h-4 w-4 ml-2 [&>path]:stroke-[3]" />
            //                                                 </Link>
            //                                             </td>
            //                                         </Link>
            //                                     ))}
            //                                 </tbody>
            //                             </table>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>

            //         </div>

            //     </main>

            // </>

            // <>
            //     <div>

            //         {/* Sidebar */}
            //         <Sidebar/>

            //         {/* Content */}
            //         <main className="lg:pl-72">
              

            //                 {/* Header */}
            //                 {/* <Header
            //                     title='Your Tracked Shops'
            //                     page='Tracked Shops'
            //                     icon={<BuildingStorefrontIcon className='text-white h-8 w-8'/>}/> */}

            //                 {/* Main */}
            //                 <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">

            //                     {/* Back button */}
            //                     <div className='pb-8'>             
            //                         <Link to={'/shops'} className="text-emerald-600 hover:text-emerald-900 text-sm font-medium flex items-center">
            //                             <ArrowLeftIcon className="h-4 w-4 mr-2 [&>path]:stroke-[3]" />
            //                             <div>All tracked stores</div>
            //                         </Link>
            //                     </div>

            //                     {/* Shop details */}
            //                     <div className="rounded-lg shadow-sm ring-1 ring-gray-900/5 mb-8 overflow-hidden">
            //                         <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
            //                             <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
            //                                 <div className="flex items-center gap-x-6">

            //                                     {/* Shop image */}
            //                                     <img
            //                                         src={this.state.shop.favicon}
            //                                         alt=""
            //                                         className="h-16 w-16 flex-none rounded-full ring-1 ring-gray-900/10"/>

            //                                     {/* Shop name */}
            //                                     <h1>      
            //                                         <div className="text-base font-semibold leading-6 text-gray-900">{this.state.shop.name}</div>
            //                                         <div className="text-sm leading-6 text-gray-500">{this.state.shop.domain}</div>
            //                                     </h1>

            //                                 </div>
            //                             </div>
            //                         </div>
            //                         <div className='px-4 py-5 sm:p-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-8 border-t border-gray-900/5'>

                                        // {/* Tracked by */}
                                        // <div className='cols-1'>
                                        //     <dt className="text-gray-500 text-sm font-medium">Tracked by</dt>
                                        //     <dd className="font-semibold text-gray-900 mt-2">
                                        //         {this.state.shop.trackByUserCount}
                                        //     </dd>
                                        // </div>

                                        // {/* Creation date */}
                                        // <div className='cols-1'>
                                        //     <dt className="text-gray-500 text-sm font-medium">Creation date</dt>
                                        //     <dd className="font-semibold text-gray-900 mt-2">
                                        //         {this.state.shop.createdDate}
                                        //     </dd>
                                        // </div>

                                        // {/* Theme */}
                                        // <div className='cols-1'>
                                        //     <dt className="text-gray-500 text-sm font-medium">Theme</dt>
                                        //     <dd className="font-semibold text-gray-900 mt-2">
                                        //         {this.state.shop.theme}
                                        //     </dd>
                                        // </div>

                                        // {/* Country */}
                                        // <div className='cols-1'>
                                        //     <dt className="text-gray-500 text-sm font-medium">Country</dt>
                                        //     <dd className="font-semibold text-gray-900 mt-2">
                                        //         {this.state.shop.country}
                                        //     </dd>
                                        // </div>

                                        // {/* Products count */}
                                        // <div className='cols-1'>
                                        //     <dt className="text-gray-500 text-sm font-medium">Products</dt>
                                        //     <dd className="font-semibold text-gray-900 mt-2">
                                        //         {this.state.shop.productsCount}
                                        //     </dd>
                                        // </div>

            //                         </div>

            //                     </div>

            //                     <div className="relative isolate overflow-hidden mb-8 rounded-lg shadow-sm ring-1 ring-gray-900/5 mb-8 overflow-hidden">

            //                         {/* Stats */}
            //                         <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            //                             <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
            //                                 {this.state.stats.map((stat, statIdx) => (
            //                                     <div 
            //                                         key={stat.name}
            //                                         className={this.classNames(
            //                                             statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
            //                                             'flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8'
            //                                         )}>
            //                                             <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
            //                                             <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
            //                                                 {stat.value}
            //                                             </dd>
            //                                     </div>
            //                                 ))}
            //                             </dl>
            //                         </div>

            //                         {/* Effect */}
            //                         <div
            //                             className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
            //                             aria-hidden="true">
            //                             <div
            //                                 className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#10b981] to-[#4ade80]"
            //                                 style={{
            //                                     clipPath:
            //                                     'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
            //                                 }}/>
            //                         </div>

            //                     </div>

            //                     {/*  */}
            //                     <div className="mb-8 rounded-lg shadow-sm ring-1 ring-gray-900/5 p-6">
                                    // <HighchartsReact
                                    //     highcharts={Highcharts}
                                    //     options={{
                                    //         title: {
                                    //             text: ''
                                    //         },
                                    //         series: [{
                                    //             data: [1, 2, 3]
                                    //         }]
                                    //     }}/>
            //                     </div>

            //                     {/*  */}
            //                     <div className="mb-8 rounded-lg shadow-sm ring-1 ring-gray-900/5 p-6">
            //                         <HighchartsReact
            //                             highcharts={Highcharts}
            //                             options={{
            //                                 title: {
            //                                     text: ''
            //                                 },
            //                                 series: [{
            //                                     data: [1, 2, 3]
            //                                 }]
            //                             }}/>
            //                     </div>

            //                     {/* Products */}
            //                     <div className="mb-8 rounded-lg shadow-sm ring-1 ring-gray-900/5 p-8">
            //                         <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            //                             <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            //                                 <table className="min-w-full divide-y divide-gray-300">
            //                                     <thead>
            //                                         <tr>
            //                                             <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
            //                                                 Top performing products
            //                                             </th>
            //                                             <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            //                                                 Sales Today
            //                                             </th>
            //                                             <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            //                                                 Sales Yesterday
            //                                             </th>
            //                                             <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            //                                                 Sales 1 Week
            //                                             </th>
            //                                             <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
            //                                             </th>
            //                                         </tr>
            //                                     </thead>
            //                                     <tbody className="divide-y divide-gray-200 bg-white">
            //                                         {this.state.products.map((product) => (
            //                                             <tr key={product.id}>

            //                                                 {/* Image */}
            //                                                 <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
            //                                                     <div className="flex items-center">
            //                                                         <div className="h-14 w-14 flex-shrink-0">
            //                                                             <img className="h-14 w-14 rounded-full" src={product.img_url} alt="" />
            //                                                         </div>
            //                                                         <div className="ml-4">
            //                                                             <div className="font-medium text-gray-900">{product.name}</div>
            //                                                             <div className="mt-1 text-gray-500">{product.domain}</div>
            //                                                         </div>
            //                                                     </div>
            //                                                 </td>

            //                                                 {/* Today's stats */}
            //                                                 <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
            //                                                     <div className="text-gray-900">{product.salesTodayAmount}</div>
            //                                                     <div className="mt-1 text-gray-500">{product.salesTodayCount}</div>
            //                                                 </td>

            //                                                 {/* Yesterday's stats */}
            //                                                 <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
            //                                                     <div className="text-gray-900">{product.salesYesterdayAmount}</div>
            //                                                     <div className="mt-1 text-gray-500">{product.salesYesterdayCount}</div>
            //                                                 </td>

            //                                                 {/* Week's stats */}
            //                                                 <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
            //                                                     <div className="text-gray-900">{product.salesWeekAmount}</div>
            //                                                     <div className="mt-1 text-gray-500">{product.salesWeekCount}</div>
            //                                                 </td>

            //                                                 {/* Track button */}
            //                                                 <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
            //                                                     <button
            //                                                         type="button"
            //                                                         className='text-emerald-600 hover:text-emerald-900'>
            //                                                         Track
            //                                                     </button>
            //                                                 </td>
                                                            
            //                                             </tr>
            //                                         ))}
            //                                     </tbody>
            //                                 </table>
            //                             </div>
            //                         </div>
            //                     </div>

            //                 </div>

                   
            //         </main>

            //     </div>
            // </>
    //     )
    // }
});