const ApiService = {

    /**
     * Gets the store page data.
     * @param {*} storeId the store id.
     * @param {*} startDate the start date.
     * @param {*} endDate the end date.
     * @returns the store page data as promise.
     */
    storePage(storeId, startDate, endDate) {
        return new Promise((resolve, reject) => {
            httpRequest('get', `https://api.trackvision.io:8000/store_page/${storeId}?startDate=${startDate}&endDate=${endDate}`).then(response => {
                resolve(response);
            }, (response) => {
                reject(response);
            });
        });
    },

    /**
     * Gets the store page products data.
     * @param {*} storeId the store id.
     * @param {*} startDate the start date.
     * @param {*} endDate the end date.
     * @returns the store page products data as promise.
     */
    storePageProducts(storeId, startDate, endDate) {
        return new Promise((resolve, reject) => {
            httpRequest('get', `https://api.trackvision.io:8000/store_products/${storeId}?startDate=${startDate}&endDate=${endDate}`).then(response => {
                resolve(response);
            }, (response) => {
                reject(response);
            });
        });
    },

	/**
	 * Gets the shops with details.
	 * @param {*} shopIds the shop ids.
	 * @returns The shops with details as a promise.
	 */
    shopsWithDetails(shopIds) {
        return new Promise((resolve, reject) => {
            httpRequest('get', `https://api.trackvision.io:8000/user_store_list/${shopIds}`).then(response => {
                resolve(response);
            }, (response) => {
                reject(response);
            });
        });
    },

    /**
     * Adds a shop via the trackvision api.
     * @param url the shop url.
     * @returns the promise.
     */
    addShop(url) {
        return new Promise((resolve, reject) => {
            httpRequest('post', 'https://api.trackvision.io:8000/add_shopify_store', {
                url: url
            }).then(response => {
                resolve(response);
            }, (response) => {
                reject(response);
            });
        });
    },

    shop(shopId) {
        return new Promise((resolve, reject) => {
            httpRequest('get', `https://api.trackvision.io:8000/store/${shopId}`).then(response => {
                resolve(response);
            }, (response) => {
                reject(response);
            });
        });
    },

    shopSales(shopId, from, to) {
        return new Promise((resolve, reject) => {
            httpRequest('get', `https://api.trackvision.io:8000/sales/${shopId}?startDate=${from}&endDate=${to}`).then(response => {
                resolve(response);
            }, (response) => {
                reject(response);
            });
        });
    },

    productSales(shopId, productId, from, to) {
        return new Promise((resolve, reject) => {
            httpRequest('get', `https://api.trackvision.io:8000/sales/${shopId}/${productId}?startDate=${from}&endDate=${to}`).then(response => {
                resolve(response);
            }, (response) => {
                reject(response);
            });
        });
    },

    products(shopId) {
        return new Promise((resolve, reject) => {
            httpRequest('get', `https://api.trackvision.io:8000/products/${shopId}`).then(response => {
                resolve(response);
            }, (response) => {
                reject(response);
            });
        });
    },

};

export default ApiService;

/**
 * Makes a http request.
 * @param httpMethod the http method.
 * @param url the request url.
 * @param body the request body.
 * @returns the promise.
 */
function httpRequest(httpMethod, url, body) {
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: httpMethod,
            headers: {
                "Content-Type": "application/json"
            },
            body: body == null ? null : JSON.stringify(body)
        })
        .then((response) => {
            return response.json();
        })
        .then((response) => {

            // If there is an error.
            if (response && response.error) {
                reject(response);
            }

            resolve(response);
            // Resolve the promise.
            resolve(response);
        })
        .catch((response) => {

            // Log the response.
            console.log(response);

            // Reject the promise.
            reject(response);
        });
    });
};