import React from 'react';
import { withRouter } from '../components/withRouter';
import Sidebar from '../components/sidebar';
import Header from '../components/header';
import { PlusIcon, ArrowPathIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import WaveIllustration from '../svgs/wave';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { db } from '../firebase';
import { collection, getDocs, query, setDoc, where, doc, addDoc } from "firebase/firestore";
import ApiService from '../services/apiservice.js';
import { ToastContainer, toast } from 'react-toastify';
import Addshop from '../components/addshop';
import WebService from '../services/webservice';
import Limit from '../components/limit';

export default withRouter(class AddShopPage extends React.Component {

    /**
     * Constructor.
     */
    constructor() {
        super();

        // Initial state.
        this.state = {
            shops: [],
            loadingShops: true,
            showHeader: true
        };

        // Bind functions.
        this.getShops = this.getShops.bind(this);

        // Set title
        document.title = 'Add shop - Track Vision';
    };

    /**
     * Component did mount.
     */
    componentDidMount() {

        // Auth observer.
        this.authUnsubscribe = onAuthStateChanged(auth, user => {
            if (user) {

                // Check if the user has an active subscription.
                WebService.userDetails(user).then(userDetails => {
                    if(!userDetails.hasActiveSubscription) {
                        this.props.navigate('/settings?tab=plan&showSelectAPlan=true');
                    }
                    this.setState({
                        userDetails: userDetails
                    });
                }, error => {
                    toast(error);
                });

                // Set the user.
                this.setState({
                    user: user
                }, () => {

                    // Get the shops.
                    this.getShops();

                });
            }
        });
    };

    /**
     * Component will unmount.
     */
    componentWillUnmount() {

        // Unsubscribe the auth observer.
        this.authUnsubscribe();
    };

    /**
     * Gets the shops.
     */
    getShops() {

        // Set loading.
        this.setState({
            loadingShops: true
        });

        // Get the shops.
        WebService.shopsWithDetails(this.state.user.uid).then(shops => {

            // Add the stores to state.
            this.setState({
                shops: shops.stores,
                loadingShops: false
            });
            
        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                loadingShops: false
            });

        });
    };

    /**
     * 
     */
    render() {
        return (
            <>
            <div className='absolute w-full h-full flex items-end'>  

                <WaveIllustration></WaveIllustration>
            </div>

                {/* Toast */}
                <ToastContainer autoClose={3000} theme='dark'/>

                {/* Sidebar */}
                {this.state.showHeader ?
                    <Sidebar
                        title='Tracked Shops'
                        userDetails={this.state.userDetails}/>  
                : null}

                {/* Content */}
                <main className="lg:pl-72">

                    {/* Header */}
                    {this.state.showHeader ?
                        <Header
                            title='Add shop'
                            subtitle='Add Any Shopify Store'
                            icon={<PlusIcon className='h-8 w-8 mb-1'/>}
                            userDetails={this.state.userDetails}/>
                    : null}

                    {/* Main */}
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">

                        {/* Loading */}
                        {this.state.loadingShops ?
                            <div className='absolute h-full rounded-lg w-full' >
                                <div className='loading mb-6 w-full' style={{ height: '3rem' }}></div>
                            </div>
                        : null}

                        {/* Add shop */}
                        <Addshop
                            shops={this.state.shops}
                            userId={this.state.user?.uid}
                            loadingShops={this.state.loadingShops}
                            userDetails={this.state.userDetails}
                            shopAdded={() => {

                                // Get the shops.
                                this.setState({
                                    shops: [],
                                    showHeader: false
                                }, () => {
        
                                    this.setState({
                                        showHeader: true
                                    });

                                    // Get the user details.
                                    WebService.userDetails(this.state.user).then(userDetails => {
                                        
                                        this.setState({
                                            userDetails: userDetails
                                        });

                                        // Get the stores.
                                        this.getShops();

                                    }, error => {
                                        toast(error);
                                    });

                                });

                            }}/>


                            

                    </div>
                </main>

            </>
        )
    }
});