import React from 'react';
import { withRouter } from './withRouter';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { PlusIcon, ArrowPathIcon, LockClosedIcon, KeyIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-toastify';
import WebService from '../services/webservice';
import Limit from './limit';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid'
import Lightbox from './lightbox';

export default class Prices extends React.Component {

    /**
     * Constructor.
     */
    constructor() {
        super();

        // Initial state.
        this.state = {
            frequencies: [
                { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
                { value: 'annually', label: 'Annually', priceSuffix: '/year' },
            ],
            frequency: null,
            showSelectAPlan: WebService.getQueryParam('showSelectAPlan') == 'true' ? true: false
        };

        // Bind functions.
        this.classNames = this.classNames.bind(this);
        this.isSubscribedToProduct = this.isSubscribedToProduct.bind(this);
        this.isSubscribedToAnyProduct = this.isSubscribedToAnyProduct.bind(this);
        this.isCancelled = this.isCancelled.bind(this);
        this.buyButton = this.buyButton.bind(this);
    };

    /**
     * The component did mount.
     */
    componentDidMount() {

        // Set the initial frequency.
        this.setState({
            frequency: this.state.frequencies[0]
        });
    };

    /**
     * Gets the class name.
     * @param  {...any} classes the original classes.
     * @returns 
     */
    classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    };

    isSubscribedToAnyProduct() {
        return this.props.subscriptions?.length > 0;
    }

    isCancelled(priceId) {
        let cancelled = false;
        this.props.subscriptions?.forEach(subscription => {
            const index = subscription.prices.indexOf(priceId);
            if (index != -1) {
                cancelled = subscription.status == 'cancelled' || subscription.status == 'expired';
            }
        });
        return cancelled;
    };

    isSubscribedToProduct(priceId) {
        let subscribed = false;
        this.props.subscriptions?.forEach(subscription => {
            subscription.prices?.forEach(price => {
                if (priceId == price) {
                    subscribed = true;
                }
                
            });
        });
        return subscribed;
    };

    buyButton(tier) {
        return (
            <button
                onClick={() => {

                    if (this.isSubscribedToAnyProduct()) {

                        // Switch plan.
                        this.props.switchPlan();

                
                    } else {

                        // Buy.
                        this.props.buyPlan(this.state.frequency?.value == 'monthly' ? tier.price.monthlyId : tier.price.annuallyId);
            
                    }
                }}
                href={tier.href}
                aria-describedby={tier.id}
                className={this.classNames(
                tier.featured
                    ? 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white'
                    : 'bg-emerald-500 text-white shadow-sm hover:bg-emerald-400 focus-visible:outline-emerald-500',
                'mt-6 w-full block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                )}>
                {
                    <>
                        {this.isSubscribedToAnyProduct() ? 
                            <>
                                Switch
                            </>
                        : 
                            <>
                                Buy
                            </>
                        }
                    </>                                                 
                }
            </button>
        );
    };

    /**
     * Render.
     */
    render() {
        return (
            <>

                {/* Show select a plan */}
                {this.state.showSelectAPlan ? 
                    <Lightbox
                         onDismiss={() => {
         
                            this.setState({
                                showSelectAPlan: false
                            });

                         }}>
                         <div className='text-center w-full'>
                             <div className='flex items-center justify-center pb-5'>
                                 <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-emerald-100">
                                     <KeyIcon className="h-10 w-10 text-emerald-600" aria-hidden="true" />
                                 </div>
                             </div>
                             <div className='text-base font-semibold leading-6 text-gray-900'>Select a plan to get full access</div>
                             <p className='text-sm text-gray-500 pb-3'>Upgrade your plan to access all tools</p>
                             <button onClick={() => {

                                this.setState({
                                    showSelectAPlan: false
                                });

                             }} className='bg-emerald-500 text-white shadow-sm hover:bg-emerald-400 focus-visible:outline-emerald-500 mt-6 w-full block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'>
                                Select a plan
                             </button>
                         </div>
                     </Lightbox>
                : null}

                {/* Loading */}
                {this.props.loadingSubscriptions || this.props.loadingSubscription || this.props.loadingCheckout || this.props.loadingPortal ?
                    <>
                        <div className='flex justify-center mt-10'>
                            <div className='loading' style={{ height: '3rem', width: '10rem' }}></div>
                        </div>
                        <div className='isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
                            <div className='loading mb-6 w-full' style={{ height: '30rem' }}></div>
                            <div className='loading mb-6 w-full' style={{ height: '30rem' }}></div>
                            <div className='loading mb-6 w-full' style={{ height: '30rem' }}></div>
                        </div>
                    </>
                : null}


               {!this.props.loadingSubscriptions && !this.props.loadingSubscription && !this.props.loadingCheckout && !this.props.loadingPortal ?
                    <div className="fade-in">
                        <div className="mx-auto">

                            <div className="flex mt-10 justify-center">

                                <RadioGroup
                                    value={this.state.frequency}
                                    onChange={(value) => {
                                        this.setState({
                                            frequency: value
                                        })
                                    }}
                                    className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200">
                                    <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
                                    {this.state.frequencies.map((option) => (
                                        <RadioGroup.Option
                                            key={option.value}
                                            value={option}
                                            className={({ checked }) =>
                                                this.classNames(
                                                    checked ? 'bg-emerald-500 text-white' : 'text-gray-500',
                                                    'cursor-pointer rounded-full px-2.5 py-1'
                                                )
                                            }>
                                            <span>{option.label}</span>
                                        </RadioGroup.Option>
                                    ))}
                                </RadioGroup>

                            </div>

                            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                                {this.props.tiers?.map((tier, index) => (
                                    <div
                                        key={index}
                                        className={this.classNames(
                                            this.state.frequency?.value == 'monthly' && this.isSubscribedToProduct(tier.price.monthlyId)  ||
                                            this.state.frequency?.value == 'annually' && this.isSubscribedToProduct(tier.price.annuallyId) ? 'ring-emerald-500' : 'ring-gray-200',
                                            'rounded-3xl p-8 ring-1 xl:p-10'
                                        )}>

                                        <div className="flex items-center justify-between gap-x-4">

                                            {/* Name */}
                                            <h3
                                                id={tier.id}
                                                className={this.classNames(
                                                tier.featured ? 'text-white' : 'text-gray-900',
                                                'text-lg font-semibold leading-8'
                                                )}>
                                                {tier.name}
                                            </h3>

                                            {this.state.frequency?.value == 'monthly' && this.isCancelled(tier.price.monthlyId) ||
                                             this.state.frequency?.value == 'annually' && this.isCancelled(tier.price.annuallyId) ? 
                                                <p className="rounded-full bg-gray-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-gray-600">
                                                    Cancelled plan
                                                </p>
                                            :
                                                <>
                                                    {this.isSubscribedToProduct(this.state.frequency?.value == 'monthly' ? tier.price.monthlyId : tier.price.annuallyId) ?
                                                        <p className="rounded-full bg-emerald-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-emerald-600">
                                                            Current plan
                                                        </p>
                                                    :
                                                        <>
                                                            {tier.mostPopular ? (
                                                                <p className="rounded-full bg-emerald-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-emerald-600">
                                                                    Most popular
                                                                </p>
                                                            ) : null}
                                                        </>
                                                    }
                                                </>
                                            }
                                        </div>

                                        {/* Description */}
                                        <p className={this.classNames(tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-4 text-sm leading-6')}>
                                            {tier.description}
                                        </p>
                                            
                                        <p className="mt-6 flex items-baseline gap-x-1">

                                            {/* Price  */}
                                            <span
                                                className={this.classNames(
                                                'text-gray-900 text-4xl font-bold tracking-tight'
                                                )}>
                                                    {tier.price[this.state.frequency?.value]}
                                            </span>

                                            {/* Price suffix */}
                                            {typeof tier.price !== 'string' ? (
                                                <span
                                                    className={this.classNames(
                                                    tier.featured ? 'text-gray-300' : 'text-gray-600',
                                                        'text-sm font-semibold leading-6'
                                                    )}>
                                                    {this.state.frequency?.priceSuffix}
                                                </span>
                                            ) : null}

                                        </p>

                                        {this.state.frequency?.value == 'monthly' && this.isSubscribedToProduct(tier.price.monthlyId) ||
                                        this.state.frequency?.value == 'annually' && this.isSubscribedToProduct(tier.price.annuallyId) ? 
                                            
                                            <>

                                                {(this.state.frequency?.value == 'monthly' && this.isCancelled(tier.price.monthlyId)  || (this.state.frequency?.value == 'annually' && this.isCancelled(tier.price.annuallyId))) ? 
                                                                // Buy plan.
                                                                <>
                                                                    {this.buyButton(tier)}
                                                                </>
                                                :   
                                                    <div>
                                                        <button
                                                            onClick={this.props.cancelPlan}
                                                            href={tier.href}
                                                            aria-describedby={tier.id}
                                                            className={this.classNames(
                                                            tier.featured
                                                                ? 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white'
                                                                : 'border-red border-1 ring-1 ring-inset ring-red-400 text-red-400 shadow-sm',
                                                            'mt-6 w-full block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                                                            )}>
                                                            Cancel plan
                                                        </button>
                                                    </div>
                                                }
                                            </>
                                        :

                                            // Buy plan.
                                            <>
                                                {this.buyButton(tier)}
                                            </>
                                        }

                                        {/* Features */}
                                        <ul
                                            role="list"
                                            className={this.classNames(
                                            tier.featured ? 'text-gray-300' : 'text-gray-600',
                                            'mt-8 space-y-3 text-sm leading-6 xl:mt-10')}>
                                            {tier.features?.map((feature, index) => (
                                                <li key={index} className="flex gap-x-3">
                                                    <CheckIcon
                                                        className={this.classNames(tier.featured ? 'text-white' : 'text-emerald-600', 'h-6 w-5 flex-none')}
                                                        aria-hidden="true"/>     
                                                        <div>
                                                            {feature.label}
                                                        </div>
                                                        <div className='ml-auto'>
                                                            {feature.value}
                                                        </div>
                                                </li>
                                            ))}
                                        </ul>

                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                : null}
            </>
        );
    };
};