import React from 'react';
import { withRouter } from '../components/withRouter';
import Sidebar from '../components/sidebar';
import { HomeIcon } from '@heroicons/react/24/solid'
import Header from '../components/header';
import AffiliateIllustration from '../svgs/affiliateillustration';
import WelcomeScreenIllustration from '../svgs/welcomescreenillustration';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { db } from '../firebase';
import WebService from '../services/webservice';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

export default withRouter(class DashboardPage extends React.Component {

    /**
     * Constructor.
     */
    constructor() {
        super();

        // Initial state.
        this.state = {
            loading: true,
            loadingShop: true,
            shopName: null,
            revenue: 0,
            orders: 0,
            aov: 0,
            productsCount: 0
        };

        // Bind funcitons.
        this.getShops = this.getShops.bind(this);

        // Set title
        document.title = 'Dashboard - Track Vision';
    };

    /**
     * Component did mount.
     */
    componentDidMount() {

        // Auth observer.
        this.authUnsubscribe = onAuthStateChanged(auth, user => {
            if (user) {

                // Check if the user has an active subscription.
                WebService.userDetails(user).then(userDetails => {
                    if(!userDetails.hasActiveSubscription) {
                        this.props.navigate('/settings?tab=plan&showSelectAPlan=true');
                    }
                    this.setState({
                        userDetails: userDetails
                    });
                }, error => {
                    toast(error);
                });

                // Set the user.
                this.setState({
                    user: user,
                }, () => {

                    // Get the shops.
                    this.getShops();

                });
            }
        });
    };

    /**
     * Component will unmount.
     */
    componentWillUnmount() {

        // Unsubscribe the auth observer.
        this.authUnsubscribe();
    };

    /**
     * Gets the shops.
     */
    getShops() {

        // Set loading.
        this.setState({
            loadingShops: true
        });

        // Get the shops.
        WebService.shopsWithDetails(this.state.user.uid).then(shops => {

            let dollar = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            });

            // Format numbers.
            let topShop = null;
            shops.stores?.forEach(shop => {

                // Determine the highest performing shop.
                if (topShop == null || shop.total_revenue_today > topShop.total_revenue_today) {
                    topShop = shop;
                    topShop.aov = shop.total_orders_today > 0 ? dollar.format((shop.total_revenue_today / shop.total_orders_today)) : '$0.00';
                    topShop.orders = shop.total_orders_today.toLocaleString();
                    topShop.revenue = dollar.format(shop.total_revenue_today);
                }
            });

            // Add the stores to state.
            this.setState({
                loading: false,
                shopName: topShop?.name,
                revenue: topShop?.revenue,
                orders: topShop?.orders,
                aov: topShop?.aov,
                productsCount: 0
            });
            
        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                loadingShops: false
            });

        });
    };

    /**
     * 
     */
    render() {
        return (
            <>
                <div>

                    {/* Sidebar */}
                    <Sidebar
                        title='Dashboard'
                        userDetails={this.state.userDetails}/>

                    {/* Content */}
                    <main className="lg:pl-72">

                        {/* Header */}
                        <Header
                            title='Dashboard'
                            subtitle='Welcome to your dashboard'
                            icon={<HomeIcon className='h-8 w-8 mb-1'/>}
                            userDetails={this.state.userDetails}/>

                        {/* Main */}
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">

                            {/* Top store */}
                            <div className="pb-5">
                                {this.state.loading ?
                                    <div className='loading w-full' style={{ height: '1.5rem' }}></div>
                                :
                                    <>
                                        {this.state.shopName ? 
                                            <h3 className="text-base font-semibold text-gray-900">
                                                Today's top performing store 
                                                <span className='text-emerald-600'> {this.state.shopName}</span>
                                            </h3>
                                        :null}
                                    </>
                                }
                            </div>

                            {this.state.loading || !this.state.loading && this.state.shopName ?
                                <div className='mb-8'>
                                    {/* <h3 className="text-base font-semibold leading-6 text-gray-900">Last 30 days</h3> */}
                                    <dl className="mt-5 grid grid-cols-2 gap-5 lg:grid-cols-4">
                        
                                        <div className="overflow-hidden rounded-lg bg-emerald-400 px-4 py-5 shadow sm:p-6">
                                            {this.state.loading ?
                                                    <div className='loading w-full' style={{height: '4rem'}}></div>
                                                :
                                                <>
                                                    <dt className="truncate text-sm font-medium text-white">Total Revenue</dt>
                                                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-white">{this.state.revenue}</dd>
                                                </>
                                            }
                                        </div>

                                        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                            {this.state.loading ?
                                                    <div className='loading w-full' style={{height: '4rem'}}></div>
                                                :
                                                <>
                                                    <dt className="truncate text-sm font-medium text-gray-500">Total Sales</dt>
                                                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{this.state.orders}</dd>
                                                </>
                                            }
                                        </div>

                                        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                            {this.state.loading ?
                                                    <div className='loading w-full' style={{height: '4rem'}}></div>
                                                :
                                                <>
                                                    <dt className="truncate text-sm font-medium text-gray-500">AOV</dt>
                                                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{this.state.aov}</dd>               
                                                </>
                                            }
                                        </div>

                                        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                            {this.state.loading ?
                                                    <div className='loading w-full' style={{height: '4rem'}}></div>
                                                :
                                                <>
                                                    <dt className="truncate text-sm font-medium text-gray-500">Products</dt>
                                                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{this.state.aov}</dd> 
                                                </>
                                            }
                                        </div>
                        
                                    </dl>
                                </div>
                            : null}

                            <div className="grid grid-rows-1 grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-8">

                                {/* Welcome */}
                                <div className='flex rounded-lg shadow-sm ring-1 ring-gray-900/5 px-8 py-8 col-span-1 lg:col-span-2 relative overflow-hidden'>

                                    {/* Welcome SVG */}
                                    <div className='h-full' style={{ width: '50%' }}>
                                        <WelcomeScreenIllustration/>
                                    </div>
                                    
                                    {/* Welcome text */}
                                    <div className='h-full text-right' style={{ width: '50%' }}>
                                        <div className="text-2xl sm:text-5xl font-bold text-gray-900 mb-6">Welcome to <span className='text-emerald-500'>Trackvision</span></div>
                                        <p className='mb-6 text text-gray-500'>Thank you for choosing Trackvision! Let's get started shall we? Click the Button below to add your first Shopify Store.</p>
                                        <Link to='/shops' className="rounded-md bg-emerald-500 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                                            Add Shopify Store
                                        </Link>
                                    </div>

                                    {/* Effect */}
                                    <div
                                        className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
                                        aria-hidden="true">
                                        <div
                                            className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#10b981] to-[#4ade80]"
                                            style={{
                                                clipPath:
                                                'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                                            }}/>
                                    </div>

                                </div>

                                {/* Affiliate */}
                                <div className='flex flex-col rounded-lg shadow-sm ring-1 ring-gray-900/5 px-8 py-8 col-span-1 relative overflow-hidden'>

                                    {/* Affiliate Text */}
                                    <div>
                                        <div className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-4">Our <span className='text-emerald-500'>Affiliate</span> Program</div>
                                        <p className='mb-6 text text-gray-500'>Be our affiliate and secure special offers!</p>
                                        <Link to='/shops' className="rounded-md bg-emerald-500 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                                            Get offers now
                                        </Link> 
                                    </div>

                                    {/* Affiliate SVG */}
                                    <AffiliateIllustration/>

                                    {/* Effect */}
                                    <div
                                        className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
                                        aria-hidden="true">
                                        <div
                                            className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#10b981] to-[#4ade80]"
                                            style={{
                                                clipPath:
                                                'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                                            }}/>
                                    </div>

                                </div>

                            </div>
                            
                        </div>

                    </main>
                
                </div>
            </>
        )
    }
});