import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthContextProvider } from './context/authcontext';
import ProtectedRoute from './components/protectedroute';
import LoginPage from './pages/login';
import SignupPage from './pages/signup';
import DashboardPage from './pages/dashboard';
import ShopsPage from './pages/shops';
import ShopPage from './pages/shop';
import ProductsPage from './pages/products';
import ProductPage from './pages/product';
import Addshop from './pages/addshop';
import Settings from './pages/settings';

function App() {
    return (
        <AuthContextProvider>
            <Routes>
                <Route path='/login' element={<LoginPage />} />
                <Route path='/signup' element={<SignupPage />} />
                <Route path='/dashboard' element={<ProtectedRoute><DashboardPage /></ProtectedRoute>}/>
                <Route path='/shops' element={<ProtectedRoute><ShopsPage /></ProtectedRoute>}/>
                <Route path='/shop' element={<ProtectedRoute><ShopPage /></ProtectedRoute>}/>
                <Route path='/products' element={<ProtectedRoute><ProductsPage /></ProtectedRoute>}/>
                <Route path='/product' element={<ProtectedRoute><ProductPage /></ProtectedRoute>}/>
                <Route path='/addshop' element={<ProtectedRoute><Addshop /></ProtectedRoute>}/>
                <Route path='/settings' element={<ProtectedRoute><Settings /></ProtectedRoute>}/>
                <Route path='*' element={<ProtectedRoute><DashboardPage /></ProtectedRoute>}/>
            </Routes>
        </AuthContextProvider>
    );
}

export default App;

// // import logo from './logo.svg';
// // import './App.css';

// // function App() {
// //   return (
// //     <div className="App">
// //       <header className="App-header">
// //         <img src={logo} className="App-logo" alt="logo" />
// //         <p>
// //           Edit <code>src/App.js</code> and save to reload.
// //         </p>
// //         <a
// //           className="App-link"
// //           href="https://reactjs.org"
// //           target="_blank"
// //           rel="noopener noreferrer"
// //         >
// //           Learn React
// //         </a>
// //       </header>
// //     </div>
// //   );
// // }

// // export default App;
// ///////////////////

// // import * as ReactDOM from "react-dom/client";
// // import { createBrowserRouter, RouterProvider } from "react-router-dom";
// // import "./index.css";

// // const router = createBrowserRouter([
// // 	{
// // 		path: "/",
// // 		element: <div>Hello world!</div>,
// // 	}
// // ]);

// // ReactDOM.createRoot(document.getElementById("root")).render(
// //   <React.StrictMode>
// //     <RouterProvider router={router} />
// //   </React.StrictMode>
// // );

// import React from 'react';
// import { Route, RouterProvider, Routes } from 'react-router-dom';
// // import { AuthContextProvider } from './context/authcontext';
// // import ProtectedRoute from './components/protectedroute';
// // import LoginPage from './pages/login';
// // import SignupPage from './pages/signup';
// // import DashboardPage from './pages/dashboard';
// // import ShopsPage from './pages/shops';
// // import ProductsPage from './pages/products';
// // import Addshop from './pages/addshop';

// function App() {
//     return (
// 		<RouterProvider>
//         // <AuthContextProvider>
//             <Routes>
//                 <Route path='/login' element={<div>112312</div>} />
//                 {/* <Route path='/signup' element={<SignupPage />} /> */}
//                 {/* <Route path='/dashboard' element={<ProtectedRoute><DashboardPage /></ProtectedRoute>}/>
//                 <Route path='/shops' element={<ProtectedRoute><ShopsPage /></ProtectedRoute>}/>
//                 <Route path='/products' element={<ProtectedRoute><ProductsPage /></ProtectedRoute>}/>
//                 <Route path='/addshop' element={<ProtectedRoute><Addshop /></ProtectedRoute>}/>
//                 <Route path='*' element={<ProtectedRoute><DashboardPage /></ProtectedRoute>}/> */}
//             </Routes>
//         // </AuthContextProvider>
//     );
// }

// export default App;