import React from 'react';
import { withRouter } from '../components/withRouter';
import Sidebar from '../components/sidebar';
import { ShoppingBagIcon } from '@heroicons/react/24/solid';
import Header from '../components/header';
import { Link } from 'react-router-dom';
import { PlusIcon, ArrowRightIcon, ArrowPathIcon } from '@heroicons/react/24/outline'
import { ToastContainer, toast } from 'react-toastify';
import { db } from '../firebase';
import { collection, getDocs, query, setDoc, where, doc, addDoc } from "firebase/firestore";
import ApiService from '../services/apiservice.js';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import WebService from '../services/webservice';

export default withRouter(class ProductsPage extends React.Component {

    /**
     * Constructor.
     */
    constructor() {
        super();

        // Initial state.
        this.state = {
            shops: [],
            loadingProducts: true,
            removingProduct: false,
            removingProductId: null
        };

        // Bind functions.
        this.getProducts = this.getProducts.bind(this);
        this.getStats = this.getStats.bind(this);

        // Initial state.
        this.state = {
            products: [],
            loadingProducts: true,
        };

        // Set title
        document.title = 'Products - Track Vision';
    };

    /**
     * Component did mount.
     */
    componentDidMount() {

        // Auth observer.
        this.authUnsubscribe = onAuthStateChanged(auth, user => {
            if (user) {

                // Check if the user has an active subscription.
                WebService.userDetails(user).then(userDetails => {
                    if(!userDetails.hasActiveSubscription) {
                        this.props.navigate('/settings?tab=plan&showSelectAPlan=true');
                    }
                    this.setState({
                        userDetails: userDetails
                    });
                }, error => {
                    toast(error);
                });

                // Set the user.
                this.setState({
                    user: user
                }, () => {

                    // Get the shops.
                    this.getProducts();

                });
            }
        });
    };

    /**
     * Component will unmount.
     */
    componentWillUnmount() {

        // Unsubscribe the auth observer.
        this.authUnsubscribe();
    };

    /**
     * Get the products.
     */
    getProducts() {

        // Set loading.
        this.setState({
            loadingProducts: true
        });
        
        // Get the shops.
        WebService.products(this.state.user.uid).then(products => {

            // Add the stores to state.
            this.setState({
                products: products,
                loadingProducts: false
            }, () => {

                // Get the stats.
                this.getStats();

            });
            
        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                loadingProducts: false
            });

        });
    };

    /**
     * Gets the product stats.
     */
    async getStats() {


    };

    /**
     * Removes the product.
   

    /**
     * Removes product.
     */
    removeProduct(event, productId) {

        // Prevent the default form action.
        event.preventDefault();

        // Prevent while loading.
        if (this.state.removingProduct) {
            return;
        }

        // Set loading.
        this.setState({
            removingProduct: true,
            removingProductId: productId
        });

        // Remove the store.
        WebService.removeProduct(productId, this.state.user?.uid).then(response => {

            // Toast.
            toast('Product removed!');

            // Update loaders.
            this.setState({
                removingProduct: false,
                removingProductId: null
            });

            // Get the shops.
            this.getProducts();

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                removingProduct: false,
                removingProductId: null
            });

        });

    };


    /**
     * 
     */
    render() {
        return (
            <>
            
                {/* Toast */}
                <ToastContainer autoClose={3000} theme='dark'/>

                {/* Sidebar */}
                <Sidebar
                    title='Tracked Products'
                    userDetails={this.state.userDetails}/>

                {/* Content */}
                <main className="lg:pl-72">

                    {/* Header */}
                    <Header
                        title='Your Tracked Products'
                        subtitle='Tracked Products'
                        icon={<ShoppingBagIcon className='h-8 w-8 mb-1'/>}
                        userDetails={this.state.userDetails}/>

                    {/* Main */}
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">

                        <div className='relative'>

                            {/* Loading */}
                            {this.state.loadingProducts ?
                                <div className='absolute h-full rounded-lg w-full' >
                                    <div className='loading mb-6 w-full' style={{ height: '3rem' }}></div>
                                    <div className='loading mb-2 w-full' style={{ height: '3rem' }}></div>
                                        {[...Array(8)].map((obj, index) => (
                                            <div key={index} className='fade-in loading my-2 w-full' style={{ height: '4rem' }}></div>
                                        ))}
                                </div>
                            : null}
                            
                            {/* Products */}
                            {this.state.products?.length > 0 ?
                                <div className="rounded-lg shadow-sm ring-1 ring-gray-900/5 overflow-hidden fade-in">
                                    <div className="flow-root">
                                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                                                                Product
                                                            </th>
                                                            {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                Sales Today
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                Sales Yesterday
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                Sales 1 Week
                                                            </th>
                                                            <th scope="col" className="relative py-3.5 pl-3 pr-4">

                                                            </th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        {this.state.products.map((product, index)  => (
                                                            <tr key={index} 
                                                                // className='hover:bg-slate-50' style={{ cursor: 'pointer', display: 'table-row' }}
                                                                >
                                                                <td>
                                                                    {/* <Link to={`/product?id=${product.id}`}>   */}
                                                                        <div className="flex items-center py-4 pl-4 pr-3">
                                                                            <div className="h-11 w-11 flex-shrink-0">
                                                                                <img
                                                                                    src={product.img_url}
                                                                                    className="h-12 w-12 p-2 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"/>
                                                                            </div>
                                                                            <div className="ml-4">
                                                                                <div className="text-sm font-medium text-gray-900">{product.product_title}</div>
                                                                                <div className="mt-1 text-sm text-gray-500">{product.variant_title}</div>
                                                                            </div>
                                                                        </div>
                                                                    {/* </Link> */}
                                                                </td>
                                                                {/* <td className="whitespace-nowrap text-sm text-gray-500">
                                                                    <Link to={`/shop?id=${shop.id}`}>  
                                                                        <div className="px-3">
                                                                            <div className="text-sm font-medium text-gray-900">{shop.total_orders_today ?? 0}</div>
                                                                            <div className="mt-1 text-sm text-gray-500">{shop.salesTodayCount ?? 0}</div>
                                                                        </div>
                                                                    </Link>
                                                                </td> */}
                                                                {/* <td className="whitespace-nowrap text-sm text-gray-500">
                                                                    <Link to={`/shop?id=${shop.id}`}>  
                                                                        <div className="px-3">
                                                                            <div className="text-sm font-medium text-gray-900">{shop.total_orders_yesterday ?? 0}</div>
                                                                            <div className="mt-1 text-sm text-gray-500">{shop.salesYesterdayCount ?? 0}</div>
                                                                        </div>
                                                                    </Link>
                                                                </td> */}
                                                                {/* <td className="whitespace-nowrap text-sm text-gray-500">
                                                                    <Link to={`/shop?id=${shop.id}`}>  
                                                                        <div className="px-3">
                                                                            <div className="text-sm font-medium text-gray-900">{shop.total_revenue_last_7_days ?? 0}</div>
                                                                            <div className="mt-1 text-sm text-gray-500">{shop.total_orders_last_7_days ?? 0}</div>
                                                                        </div>
                                                                    </Link>
                                                                </td> */}
                                                                <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium">
                                                                    {/* <Link to={`/product?id=${product.id}`}>  */}

                                                                        <div className="px-3 flex items-center  justify-end">
                                                                            <button 
                                                                                disabled={this.state.removingStore} 
                                                                                onClick={(event) => this.removeProduct(event, product.id)} 
                                                                                type="submit"
                                                                                className='mr-3 fade-in flex items-center rounded-md px-3.5 py-2.5 text-sm font-semibold text-red-400 border-red shadow-sm ring-1 ring-inset ring-red-400'>
                                                                                    
                                                                                    {this.state.removingProduct && this.state.removingProductId == product.id ?
                                                                                        <div className='pr-2'>
                                                                                            <ArrowPathIcon className="spin h-4 w-4 [&>path]:stroke-[3]" />
                                                                                        </div>
                                                                                    : null}

                                                                                    <div className='ml-auto'>
                                                                                        Remove
                                                                                    </div>
                                                                            </button>
                                                                            {/* <button 
                                                                                to={`/product?id=${product.id}`}
                                                                                type="button"
                                                                                className='text-emerald-600 hover:text-emerald-900 flex items-center justify-end'>
                                                                                    See details
                                                                                    <ArrowRightIcon className="h-4 w-4 ml-2 [&>path]:stroke-[3]" />
                                                                            </button> */}
                                                                        </div>

                                                                    {/* </Link> */}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : null}

                        </div>

                    </div>

                </main>

            </>
        )
    }

});