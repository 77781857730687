import React from 'react';
import { withRouter } from '../components/withRouter';
import Sidebar from '../components/sidebar';
import { BuildingStorefrontIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { ArrowLeftIcon, ArrowPathIcon, ArrowRightIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Header from '../components/header';
import { Link } from 'react-router-dom';
import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from 'highcharts'
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { db } from '../firebase';
import { collection, getDocs, query, setDoc, where, doc, addDoc } from "firebase/firestore";
import ApiService from '../services/apiservice';
import WebService from '../services/webservice';
import { ToastContainer, toast } from 'react-toastify';
import Limit from '../components/limit';
import ReactDatetimeClass from 'react-datetime';
import DateBetweenInput from '../components/datebetweeninput';
import Select from '../components/select';
import { InformationCircleIcon } from '@heroicons/react/24/solid';

// Initial dates.
const today = new Date();
const firstDay = new Date(new Date().setDate(new Date().getDate() - 30));
const lastDay = today;

export default withRouter(class ShopPage extends React.Component {

    /**
     * Constructor.
     */
    constructor() {
        super();

        // Initial state.
        this.state = {
            loadingShop: true,
            loadingShopStats: false,
            id: null,
            name: null,
            domain: null,
            trackByUserCount: null,
            theme: null,
            country: null,
            productsCount: null,
            favicon: null,
            orders: null,
            revenue: null,
            aov: null,
            topProducts: [],
            dateFrom: firstDay,
            dateTo: lastDay,
            addingProduct: false,
            addingProductId: null,
            loadingUserProdcuts: false,
            userProducts: [],
            showHeader: true,
            showLimit: false,
            noData: false,
            dateOption: { id: 'Last 30 days', name: 'Last 30 days' }
        };

        // Bind funcitons.
        this.classNames = this.classNames.bind(this);
        this.getShop = this.getShop.bind(this);
        this.trackProduct = this.trackProduct.bind(this);
        this.getUserProducts = this.getUserProducts.bind(this);
        this.removeStore = this.removeStore.bind(this);

        // Set title
        document.title = 'Shop - Track Vision';
    };

    /**
     * Component did mount.
     */
    componentDidMount() {

        // Auth observer.
        this.authUnsubscribe = onAuthStateChanged(auth, user => {
            if (user) {

                // Check if the user has an active subscription.
                WebService.userDetails(user).then(userDetails => {
                    if(!userDetails.hasActiveSubscription) {
                        this.props.navigate('/settings?tab=plan&showSelectAPlan=true');
                    }
                    this.setState({
                        userDetails: userDetails
                    });
                }, error => {
                    toast(error);
                });

                // Set the user.
                this.setState({
                    user: user
                }, () => {

                    // Get the shop.
                    this.getShop();

                    // Get the users products.
                    this.getUserProducts();

                });
            }
        });
    };

    /**
     * Component will unmount.
     */
    componentWillUnmount() {

        // Unsubscribe the auth observer.
        this.authUnsubscribe();
    };

    /**
     * 
     * @param  {...any} classes 
     * @returns 
     */
    classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    };

    /**
     * Gets the shop.
     */
    getShop() {
        
        // Set loading.
        this.setState({
            loadingShop: true,
        });

        // Get the shop id.
        const shopId = new URLSearchParams(window.location.search).get('id');

        // Dates as srtring.
        function ensureTwoChars(input) {
            if (input.toString().length == 1) {
                return `0${input}`;
            } else {
                return input;
            }
        };

        const dateFromString = `${this.state.dateFrom.getFullYear()}-${ensureTwoChars(this.state.dateFrom.getMonth() + 1)}-${ensureTwoChars(this.state.dateFrom.getDate())}`
        const dateToString = `${this.state.dateTo.getFullYear()}-${ensureTwoChars(this.state.dateTo.getMonth() + 1)}-${ensureTwoChars(this.state.dateTo.getDate())}`

        // Get the shop.
        WebService.storePage(shopId, dateFromString, dateToString).then(shopWithDetails => {

            // Currency formatter.
            let dollar = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            });

            // Get the chart data.
            let customRevenueData = [];
            let customCountData = []
            shopWithDetails?.store_sales?.forEach(day => {
                // if (new Date(day.date) >= dateFromString &&  new Date(day.date) <= dateToString) {
                    customRevenueData.push([new Date(day.date).getTime(), day.total_revenue])
                    customCountData.push([new Date(day.date).getTime(), day.total_orders])
                // }
            });

            // Set the shop details.
            this.setState({
                loadingShop: false,
                id: shopWithDetails.id,
                name: shopWithDetails.title,
                domain: shopWithDetails.url,
                theme: shopWithDetails.theme,
                country: shopWithDetails.country,
                productsCount: shopWithDetails?.products_count?.toLocaleString(),
                favicon: shopWithDetails.favicon,
                orders: shopWithDetails?.total_orders == null ? 0 : shopWithDetails?.total_orders?.toLocaleString(),
                revenue: shopWithDetails.total_revenue == null ? '$0' : dollar.format(shopWithDetails.total_revenue),
                ordersRolling24hrs: shopWithDetails?.rolling_24hrs?.total_orders == null ? 0 : shopWithDetails?.rolling_24hrs?.total_orders?.toLocaleString(),
                revenueRolling24hrs: shopWithDetails?.rolling_24hrs?.total_revenue == null ? '$0' : dollar.format(shopWithDetails?.rolling_24hrs?.total_revenue),
                aov: shopWithDetails?.rolling_24hrs?.total_orders > 0 ? dollar.format((shopWithDetails?.rolling_24hrs?.total_revenue / shopWithDetails?.rolling_24hrs?.total_orders)) : '$0.00',
                //topProducts: shopWithDetails.top_products,
                createdDate: new Date(shopWithDetails.created_at).toLocaleDateString(),
                customRevenueData: customRevenueData,
                customCountData: customCountData,
                noData: shopWithDetails.favicon == '' || shopWithDetails.favicon == null
            });

            // Get the shop stats
            this.setState({
                loadingStats: true,
            });
            
            WebService.shopStats(shopId).then(shopWithDetails => {

                this.setState({
                    loadingStats: false,
                    trackByUserCount: shopWithDetails.trackByUserCount
                });

            }, error => {
    
                // Show the error.
                toast(error);
    
                // Update loaders.
                this.setState({
                    loadingStats: false,
                });
    
            });

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                loadingShop: false,
            });

        });

        // Get the products. 
        WebService.storePageProducts(shopId, dateFromString, dateToString).then(storePageProductsResponse => {

            console.log(storePageProductsResponse);

            // Set the shop product details.
            this.setState({
                //productsCount: shopWithDetails.product_count.toLocaleString(),
                topProducts: storePageProductsResponse.top_products,
            });

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                loadingShop: false,
            });

        });

    };
    

    /**
     * Gets the users tracked products.
     */
    getUserProducts() {

        // Set loading.
        this.setState({
            loadingUserProdcuts: true,
        });

        // Get the shop.
        WebService.userProducts(this.state.user.uid).then(userProducts => {

            // Set shop in state.
            this.setState({
                loadingUserProdcuts: false,
                userProducts: userProducts,
            });

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                loadingUserProdcuts: false,
            });

        });
    };

    /**
     * Tracks a product.
     * @param {*} productId the product id. 
     */
    trackProduct(productId) {

        // Prevent while loading.
        if (this.state.addingProduct) {
            return;
        }

        // Determine if we're deleting or not.
        const adding = this.state.userProducts.map(userProduct => (userProduct.id.toString()) ).indexOf(productId.toString()) == -1;

        // Get the store id.
        const shopId = new URLSearchParams(window.location.search).get('id');

          // Check limits
          if (adding && this.state.userProducts.length > this.state.userDetails.productLimit - 1 ) {
            this.setState({
                showLimit: true
            })
            return;
        }

        // Set loading.
        this.setState({
            addingProduct: true,
            addingProductId: productId
        });

        if (adding) {

            // Add the product.
            WebService.addProduct(productId, this.state.user?.uid, shopId).then(response => {

                // Toast.
                toast('Product added!');

                // Update the product buttons.
                this.getUserProducts();

                // Update loaders.
                this.setState({
                    addingProduct: false,
                    addingProductId: null,
                    showHeader: false
                }, () => {
                    this.setState({
                        showHeader: true
                    });
                });

            }, error => {

                // Show the error.
                toast(error);

                // Update loaders.
                this.setState({
                    addingProduct: false,
                    addingProductId: null,
                });

            });

        } else {

            // Remove the product.
            WebService.removeProduct(productId, this.state.user?.uid).then(response => {

                // Toast.
                toast('Product removed!');

                // Update the product buttons.
                this.getUserProducts();

                // Update loaders.
                this.setState({
                    addingProduct: false,
                    addingProductId: null,
                    showHeader: false
                }, () => {
                    this.setState({
                        showHeader: true
                    });
                });

            }, error => {

                // Show the error.
                toast(error);

                // Update loaders.
                this.setState({
                    addingProduct: false,
                    addingProductId: null,
                });

            });

        }
    };

    /**
     * Removes the store.
     */
    removeStore() {

        // Prevent while loading.
        if (this.state.removingStore) {
            return;
        }

        // Set loading.
        this.setState({
            removingStore: true
        });

        // Get the shop id.
        const shopId = new URLSearchParams(window.location.search).get('id');

        // Remove the product.
        WebService.removeStore(shopId, this.state.user?.uid).then(response => {

            // Toast.
            toast('Store removed!');

            // Back to shops.
            window.location.href = '/shops';

            // Update loaders.
            this.setState({
                removingStore: false,
                showHeader: false
            }, () => {
                this.setState({
                    showHeader: true
                });
            });

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                removingStore: false
            });

        });

    };

    /**
     * 
     */
    render() {
        return (
            <>

                {/* Limit */}
                {this.state.showLimit ? 
                  <Limit 
                    onDismiss={() => 
                        this.setState({
                            showLimit: false
                        })
                    }/>
                : null}

                {/* Toast */}
                <ToastContainer autoClose={3000} theme='dark'/>

                {/* Sidebar */}
                {this.state.showHeader ?
                    <Sidebar
                    title='Tracked Shops'
                    userDetails={this.state.userDetails}/>
                : null}

                {/* Content */}
                <main className="lg:pl-72">

                    {/* Header */}
                    {this.state.showHeader ?
                        <Header
                            title='Your Tracked Shops'
                            subtitle='Tracked Shops'
                            icon={<BuildingStorefrontIcon className='h-8 w-8 mb-1'/>}
                            userDetails={this.state.userDetails}/>
                    : null}

                    {/* Main */}
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">

                        {/* Back button */}
                        <div className="pb-5">
                            <Link to={'/shops'} className="text-sm font-semibold text-emerald-600 hover:text-emerald-900 flex items-center">
                                <ArrowLeftIcon className="h-4 w-4 mr-2 [&>path]:stroke-[3]" />
                                <div>All tracked shops</div>
                            </Link>
                        </div>

                        {/* Banner */}
                        <div className="rounded-lg bg-gray-50 p-4 mb-8">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-gray-800">Sales data are estimates and may contain error margins</h3>
                                    <div className="mt-2 text-sm text-gray-700">
                                        Error margins increase for products with lower sales volume and decrease for products with higher sales volume.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-lg shadow-sm ring-1 ring-gray-900/5 mb-8">
                            <div className="flex items-center border-b border-gray-900/5 p-6">

                                {this.state.loadingShop ?
                                    <div className='loading w-full h-14'></div>
                                :
                                    <>
                                        {/* Logo */}
                                        {this.state.noData ?
                                            <div
                                                className="h-14 w-14 flex items-center justify-center rounded-lg bg-white object-cover ring-1 ring-gray-900/10 fade-in">
                                                <BuildingStorefrontIcon className='text-gray-400 group-hover:text-emerald-600 h-6 w-6 shrink-0'/>
                                            </div>
                                        : 
                                            <img
                                                src={this.state.favicon}
                                                className="h-14 w-14 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10 fade-in"/>
                                        }

                                        {/* Name and domain */}
                                        <div className='ml-4 fade-in'>
                                            <div className="text-base font-semibold text-gray-900 mb-1">{this.state.name}</div>
                                            <div className="text-sm text-gray-500">{this.state.domain}</div>
                                        </div>
                                    </>
                                }
            
                            </div>
                            <div className='px-4 py-5 sm:p-6 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-5 gap-8'>

                                {/* Tracked by */}
                                <div>
                                    {this.state.loadingShop ?
                                        <div className='loading w-full h-12'></div>
                                    :
                                        <>
                                            <dt className="fade-in text-sm text-gray-500">Tracked by</dt>
                                            <dd className="fade-in text-base font-medium text-gray-900 mt-1">
                                                {this.state.trackByUserCount} Users
                                            </dd>
                                        </>
                                    }
                                </div>

                                {/* Theme */}
                                <div>
                                    {this.state.loadingShop ?
                                        <div className='loading w-full h-12'></div>
                                    :
                                        <>
                                            <dt className="fade-in text-sm text-gray-500">Theme</dt>
                                            <dd className="fade-in text-base font-medium text-gray-900 mt-1" style={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                            }}>
                                                {this.state.theme}
                                            </dd>
                                        </>
                                    }
                                </div>

                                {/* Country */}
                                <div>
                                    {this.state.loadingShop ?
                                        <div className='loading w-full h-12'></div>
                                    :
                                        <>
                                            <dt className="fade-in text-sm text-gray-500">Country</dt>
                                            <dd className="fade-in text-base font-medium text-gray-900 mt-1">
                                                {this.state.country}
                                            </dd>
                                        </>
                                    }
                                </div>

                                {/* Products count */}
                                <div>
                                    {this.state.loadingShop ?
                                        <div className='loading w-full h-12'></div>
                                    :
                                        <>
                                            <dt className="fade-in text-sm text-gray-500">Products</dt>
                                            <dd className="fade-in text-base font-medium text-gray-900 mt-1">
                                                {this.state.productsCount}
                                            </dd>
                                        </>
                                    }
                                </div>

                                {/* Remove */}
                                <div>
                                    {this.state.loadingShop ?
                                            <div className='loading w-full h-12'></div>
                                        :
                                            <>
                                                <button 
                                                    disabled={this.state.removingStore} 
                                                    onClick={() => this.removeStore()} 
                                                    type="submit"
                                                    className='fade-in flex items-center rounded-md px-3.5 py-2.5 text-sm font-semibold text-red-400 border-red shadow-sm ring-1 ring-inset ring-red-400'>
                                                        
                                                        {this.state.removingStore ?
                                                            <div className='pr-2'>
                                                                <ArrowPathIcon className="spin h-4 w-4 [&>path]:stroke-[3]" />
                                                            </div>
                                                        : null}

                                                        <div className='ml-auto'>
                                                            Remove
                                                        </div>     
                                                </button>
                                            </>
                                        }
                                </div>

                            </div>
                        </div>

                        <div className='mb-8'>
                            {/* <h3 className="text-base font-semibold leading-6 text-gray-900">Last 30 days</h3> */}
                            <dl className="mt-5 grid grid-cols-2 gap-5 lg:grid-cols-4">
                   
                                <div className="overflow-hidden rounded-lg bg-emerald-400 px-4 py-5 shadow sm:p-6">
                                    {this.state.loadingShop ?
                                            <div className='loading w-full' style={{height: '5.2rem'}}></div>
                                        :
                                        <>
                                            <dt className="truncate text-xs font-medium text-white mb-1">(Rolling 24hrs)</dt>
                                            <dt className="truncate text-sm font-medium text-white">Total Revenue</dt>
                                            <dd className="mt-1 text-3xl font-semibold tracking-tight text-white">{this.state.revenueRolling24hrs}</dd>
                                        </>
                                    }
                                </div>

                                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                    {this.state.loadingShop ?
                                            <div className='loading w-full' style={{height: '5.2rem'}}></div>
                                        :
                                        <>
                                            <dt className="truncate text-xs font-medium text-gray-500 mb-1">(Rolling 24hrs)</dt>
                                            <dt className="truncate text-sm font-medium text-gray-500">Total Sales</dt>
                                            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{this.state.ordersRolling24hrs}</dd>
                                        </>
                                    }
                                </div>

                                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                    {this.state.loadingShop ?
                                            <div className='loading w-full' style={{height: '5.2rem'}}></div>
                                        :
                                        <>
                                            <dt className="truncate text-xs font-medium text-gray-500 mb-1">(Rolling 24hrs)</dt>
                                            <dt className="truncate text-sm font-medium text-gray-500">AOV</dt>
                                            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{this.state.aov}</dd>               
                                        </>
                                    }
                                </div>

                                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                    {this.state.loadingShop ?
                                            <div className='loading w-full' style={{height: '5.2rem'}}></div>
                                        :
                                        <>  
                                            <dt className="truncate text-xs font-medium text-gray-500 mb-1">(Rolling 24hrs)</dt>
                                            <dt className="truncate text-sm font-medium text-gray-500">Products</dt>
                                            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{this.state.productsCount}</dd> 
                                        </>
                                    }
                                </div>
                 
                            </dl>
                        </div>

                        {/* Date range */}
                        <div className="flex items-center justify-between rounded-lg shadow-sm ring-1 ring-gray-900/5 mb-8 p-6">
                            <div>    
                                <div>
                                    {this.state.loadingShop ?
                                        <div className='loading w-full h-12'></div>
                                    :
                                        <>
                                            <dt className="fade-in text-sm text-gray-500">Store Creation Date</dt>
                                            <dd className="fade-in text-base font-medium text-gray-900 mt-1">
                                                {this.state.createdDate}
                                            </dd>
                                        </>
                                    }
                                </div>
                            </div>
                            <div>
                                <Select
                                    label={'Date range'}
                                    icon={
                                        <svg className="w-6 h-6 text-emerald-600 group-hover:text-emerald-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                        </svg>
                                    }
                                    value={
                                        this.state.dateOption
                                    }
                                    onChange={(value) => {

                                        var dateFrom;
                                        switch (value.id) {
                                            case 'Today':
                                                dateFrom = new Date(new Date().setDate(new Date().getDate()));
                                                break;
                                            case 'Yesterday':
                                                dateFrom = new Date(new Date().setDate(new Date().getDate() - 1));
                                                break;
                                            case 'Last 7 days':
                                                dateFrom = new Date(new Date().setDate(new Date().getDate() - 7));
                                                break;
                                            case 'Last 30 days':
                                                dateFrom = new Date(new Date().setDate(new Date().getDate() - 30));
                                                break;
                                            case 'Last 90 days':
                                                dateFrom = new Date(new Date().setDate(new Date().getDate() - 90));
                                                break;
                                            case 'Last 6 months':
                                                dateFrom = new Date(new Date().setMonth(new Date().getMonth() - 30));
                                                break;
                                            case 'Last 12 months':
                                                dateFrom = new Date(new Date().setMonth(new Date().getMonth() - 12));
                                                break;
                                        }
                                        this.setState({
                                            dateOption: value,
                                            dateFrom: dateFrom,
                                        }, () => {
                                            this.getShop();
                                        });
                                    }}
                                    labelProp={'name'}
                                    options={[
                                        { id: 'Today', name: 'Today' },
                                        { id: 'Yesterday', name: 'Yesterday' },
                                        { id: 'Last 7 days', name: 'Last 7 days' },
                                        { id: 'Last 30 days', name: 'Last 30 days' },
                                        { id: 'Last 90 days', name: 'Last 90 days' },
                                        { id: 'Last 6 months', name: 'Last 6 months' },
                                        { id: 'Last 12 months', name: 'Last 12 months' },
                                    ]}/>
                            </div>
                        </div>

                        {/* Revenue Chart */}
                        <div className="rounded-lg shadow-sm ring-1 ring-gray-900/5 mb-8">
                            <div className='sm:flex p-6'>
                                {this.state.loadingShop ?
                                    <div className='loading w-full h-14'></div>
                                :
                                    <>

                                        {/* Title */}
                                        <div>
                                            <div className="fade-in text-sm text-gray-500 mr-2">Total Revenue</div>
                                            {!this.state.noData ?
                                                <div className="fade-in text-xl font-medium text-gray-900 mt-1">{this.state.revenue}</div>
                                            : null}
                                        </div>

                                        {/* Date */}
                                        {/* {!this.state.noData ?
                                            <div className='ml-auto sm:flex'>
                                                <DateBetweenInput
                                                    dateFrom={this.state.dateFrom}
                                                    dateTo={this.state.dateTo}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            dateFrom: value.dateFrom,
                                                            dateTo: value.dateTo
                                                        }, () => {
                                                            this.getShop();
                                                        });
                                                    }}/>
                                            </div>
                                        : null} */}

                                    </>
                                }
                            </div>

                            {/* Chart */}
                            <div className='p-4'>  
                                {this.state.loadingShop ?
                                    <div className='pl-2 pr-2 pb-2'>
                                        <div className='loading w-full' style={{height: '26.5rem'}}></div>
                                    </div>
     
                                :
                                    <>
                                        {!this.state.noData ? 
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={{
                                                    tooltip: {
                                                        backgroundColor: '#181e36',
                                                        style: {
                                                            color: '#ffffff'
                                                        },
                                                        pointFormatter: function() {
                                                            let dollar = new Intl.NumberFormat('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD'
                                                            });
                                                            return '<span>' + dollar.format(this.y) + '</span>'
                                                        },
                                                    },
                                                    credits: {
                                                        enabled: false
                                                    },
                                                    chart: {
                                                        type: 'area'
                                                    },
                                                    title: {
                                                        text: ''
                                                    },
                                                    plotOptions: {
                                                        series: {
                                                            fillColor: {
                                                                linearGradient: [0, 0, 0, 300],
                                                                stops: [
                                                                    [0, '#d1fae5'],
                                                                    [1, '#ffffff']
                                                                ]
                                                            }
                                                        }
                                                    },
                                                    xAxis: {
                                                        title: {
                                                            text: ''
                                                        },
                                                        type: 'datetime',
                                                        lineColor: '#e6e6e6',
                                                        labels: {
                                                            style: {
                                                                color: '#6b7280',
                                                            }
                                                        }
                                                    },
                                                    yAxis: {
                                                        title: {
                                                            text: ''
                                                        },
                                                        lineColor: '#e6e6e6',
                                                        labels: {
                                                            style: {
                                                                color: '#6b7280',
                                                            }
                                                        }
                                                    },
                                                    series: [{
                                                        name: 'Revenue',
                                                        color: '#10b981',
                                                        type: 'area',
                                                        data: this.state.customRevenueData,
                                                        marker: false,
                                                        showInLegend: false, 
                                                    }]
                                                }}/>
                                            : 
                                                <>
                                                    <div className='pl-2 pr-2 pb-2 fade-in text-base font-medium text-gray-900 mt-1' style={{marginTop: '-2rem'}}>
                                                        No data <span className='text-xs text-gray-500'>(Will be updated in 24 hours)</span>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                            </div>
                        </div>

                        {/* Sales Chart */}
                        <div className="rounded-lg shadow-sm ring-1 ring-gray-900/5 mb-8">
                            <div className='sm:flex p-6'>
                                {this.state.loadingShop ?
                                    <div className='loading w-full h-14'></div>
                                :
                                    <>
                                        {/* Title */}
                                        <div>
                                            
                                            <div className="fade-in text-sm text-gray-500 mr-2">Total orders</div>
                                                {!this.state.noData ?
                                                    <div className="fade-in text-xl font-medium text-gray-900 mt-1">{this.state.orders}</div>
                                                : null}
                                            </div>

                                        {/* Date */}
                                        {/* {!this.state.noData ?
                                            <div className='ml-auto sm:flex'>
                                                <DateBetweenInput
                                                    dateFrom={this.state.dateFrom}
                                                    dateTo={this.state.dateTo}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            dateFrom: value.dateFrom,
                                                            dateTo: value.dateTo
                                                        }, () => {
                                                            this.getShop();
                                                        });
                                                    }}/>
                                            </div>
                                        : null} */}
                                    </>
                                }
                            </div>

                            {/* Chart */}
                            <div className='p-4'>    
                                {this.state.loadingShop ?
                                    <div className='pl-2 pr-2 pb-2'>
                                        <div className='loading w-full' style={{height: '26.5rem'}}></div>
                                    </div>
                                :          
                                    <>
                                        {!this.state.noData ?    
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={{
                                                    tooltip: {
                                                        backgroundColor: '#181e36',
                                                        style: {
                                                            color: '#ffffff'
                                                        },
                                                        pointFormatter: function() {
                                                            return '<span>' + this.y.toLocaleString() + '</span>'
                                                        },
                                                    },
                                                    credits: {
                                                        enabled: false
                                                    },
                                                    chart: {
                                                        type: 'area'
                                                    },
                                                    title: {
                                                        text: ''
                                                    },
                                                    plotOptions: {
                                                        series: {
                                                            fillColor: {
                                                                linearGradient: [0, 0, 0, 300],
                                                                stops: [
                                                                    [0, '#d1fae5'],
                                                                    [1, '#ffffff']
                                                                ]
                                                            }
                                                        }
                                                    },
                                                    xAxis: {
                                                        title: {
                                                            text: ''
                                                        },
                                                        type: 'datetime',
                                                        lineColor: '#e6e6e6',
                                                        labels: {
                                                            style: {
                                                                color: '#6b7280',
                                                            }
                                                        }
                                                    },
                                                    yAxis: {
                                                        title: {
                                                            text: ''
                                                        },
                                                        lineColor: '#e6e6e6',
                                                        labels: {
                                                            style: {
                                                                color: '#6b7280',
                                                            }
                                                        }
                                                    },
                                                    series: [{
                                                        name: 'Orders',
                                                        color: '#10b981',
                                                        type: 'area',
                                                        data: this.state.customCountData,
                                                        marker: false,
                                                        showInLegend: false, 
                                                    }]
                                                }}/>
                                            : 
                                                <>
                                                    <div className='pl-2 pr-2 pb-2 fade-in text-base font-medium text-gray-900 mt-1' style={{marginTop: '-2rem'}}>
                                                        No data <span className='text-xs text-gray-500'>(Will be updated in 24 hours)</span>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                            </div>
                        </div>

                        {/* Loading */}
                        <div className='relative'>
                            {this.state.loadingShop ?
                                <div className='absolute h-full rounded-lg w-full' >
                                    <div className='loading mb-6 w-full' style={{ height: '3rem' }}></div>
                                    <div className='loading mb-2 w-full' style={{ height: '3rem' }}></div>
                                        {[...Array(8)].map((obj, index) => (
                                            <div key={index} className='fade-in loading my-2 w-full' style={{ height: '4rem' }}></div>
                                        ))}
                                </div>
                            : null}
                        </div>

                        {/* Products */}
                        {this.state.topProducts ?
                            <div className="rounded-lg shadow-sm ring-1 ring-gray-900/5 overflow-hidden">
                                <div className="flow-root">
                                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                                                            Product
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Total Revenue
                                                        </th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                            Total Orders
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                {this.state.topProducts.map((product, index)  => (
                                                    <tr key={index}>
                                                        <td>    
                                                            <div className="flex items-center py-4 pl-4 pr-3">
                                                                <div className="h-11 w-11 flex-shrink-0">
                                                                    <img
                                                                        src={product.main_image}
                                                                        className="h-12 w-12 p-2 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"/>
                                                                </div>
                                                                <div className="ml-4">
                                                                    <div className="text-sm font-medium text-gray-900">{product.title}</div>
                                                                    {/* <div className="mt-1 text-sm text-gray-500">{product.variant_title}</div> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap text-sm text-gray-500">
                                                            <div className="px-3">
                                                                <div className="text-sm font-medium text-gray-900">{product.total_revenue ?? 0}</div>
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap text-sm text-gray-500">
                                                            <div className="px-3">
                                                                <div className="text-sm font-medium text-gray-900">{product.total_orders ?? 0}</div>
                                                            </div>
                                                        </td>
                                                        {/* <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium">
                                                            <div className="px-3">
                                                                <button disabled={this.state.addingStore} onClick={() => this.trackProduct(product.product_id)} type="submit" 
                                                                className={
                                                                    this.state.userProducts.map(userProduct => (userProduct.id.toString()) ).indexOf(product.product_id.toString()) == -1 ?
                                                                    'flex items-center rounded-md bg-emerald-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-500'
                                                                    :
                                                                    'flex items-center rounded-md bg-red-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-400'
                                                                }>
                                                                    <div>
                                                                        {!this.state.addingProduct || this.state.addingProductId?.toString() != product.product_id.toString() ?
                                                                            <>
                                                                                {this.state.userProducts.map(userProduct => (userProduct.id.toString()) ).indexOf(product.product_id.toString()) == -1 ?
                                                                                    <PlusIcon className="h-4 w-4 [&>path]:stroke-[3]" />
                                                                                : null}
                                                                            </>   
                                                                        : 
                                                                            <ArrowPathIcon className="spin h-4 w-4 [&>path]:stroke-[3]" />
                                                                        }
                                                                    </div>
                                                                    <div className='ml-1'>

                                                                        {this.state.userProducts.map(userProduct => (userProduct.id.toString()) ).indexOf(product.product_id.toString()) == -1 ? 'Track' : 'Stop tracking'}
                                                                        
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </td> */}
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : null}

                    </div>

                </main>

            </>
        )
    }
});