import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../context/authcontext';

const ProtectedRoute = ({ children }) => {
  
  // The user.
  const { user } = UserAuth();

  // If the user is signed out, return them to the sign up page.
  if (!user) {
    return <Navigate to='/signup' />;
  }

  return children;
};

export default ProtectedRoute;