import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from '../components/withRouter';
import { signInWithEmailAndPassword, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';
import Lightbox from '../components/lightbox';

export default withRouter(class LoginPage extends React.Component {

    /**
     * Constructor.
     */
    constructor() {
        super();

        // Initial state.
        this.state = {
            email: '',
            password: '',
            loading: false,
            showPasswordResetLightbox: false,
            resetEmail: '',
        };

        // Bind functions.
        this.loginUserWithEmailAndPassword = this.loginUserWithEmailAndPassword.bind(this);
        this.signinWithGoogle = this.signinWithGoogle.bind(this);
        this.sendResetEmail = this.sendResetEmail.bind(this);

        // Set title
        document.title = 'Login - Track Vision';
    };

    /**
     * Sends a reset email link.
     */
    sendResetEmail() {

        // Set loading.
        this.setState({
            loading: true
        });

        // Send the password reset email.
        sendPasswordResetEmail(auth, this.state.resetEmail).then(() => {

            // Show the toast message.
            toast('Password reset email sent');

            // Hide the reset password lightbox.
            this.setState({
                showPasswordResetLightbox: false,
                loading: false
            });

        }).catch((error) => {

            // Set loading.
            this.setState({
                loading: false
            });

            // Show the error.
            toast(error.message)

            // Log the error.
            console.log(error.code,  error.message);
            
        });
    };

    /**
     * Sign up the user with email and password.
     * @param {*} event the event.
     */
    loginUserWithEmailAndPassword(event) {

        // Prevent the default form action.
        event.preventDefault();

        // Set loading.
        this.setState({
            loading: true
        });

        // Create the user.
        signInWithEmailAndPassword(auth, this.state.email, this.state.password).then((userCredential) => {

            // Navigate to the dashboard.
            this.props.navigate('/dashboard');

            // Set loading.
            this.setState({
                loading: false
            });

        }).catch((error) => {

            // Set loading.
            this.setState({
                loading: false
            });

            // Show the error.
            toast(error.message)

            // Log the error.
            console.log(error.code,  error.message);
        });
    };
    
    /**
     * Sign in with google.
     */
    signinWithGoogle() {
        signInWithPopup(auth, new GoogleAuthProvider()).then((result) => {

            // Navigate to the dashboard.
            this.props.navigate('/dashboard');

        }).catch((error) => {

            // Show the error.
            toast(error.message)

            // Log the error.
            console.log(error.code,  error.message);
            
        });
    };

    /**
     * 
     */
    render() {
        return (
            <>

                {/* Toast */}
                <ToastContainer autoClose={3000} theme='dark'/>

                {/* Password reset email */}
                {this.state.showPasswordResetLightbox ?
                    <Lightbox
                        title='Password reset'
                        buttons={(
                            <>
                                {!this.state.loading ?
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 sm:ml-3 sm:w-auto"
                                        onClick={() => this.sendResetEmail()}>
                                            Send reset link
                                    </button>
                                : null}
                            </>
                        )}
                        onDismiss={() => {
                            this.setState({
                                showPasswordResetLightbox: false
                            });
                        }}>
                            
                            {/* Reset email */}
                            <div className='mt-4'>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                                        value={this.state.resetEmail}
                                        onChange={(e) => {
                                            this.setState({
                                                resetEmail: e.target.value
                                            });
                                        }} />
                                </div>
                            </div>

                    </Lightbox>
                : null}

                <div className='sm:flex sm:h-screen'> 

                    <div className='w-full sm:w-1/2'>
                        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">

                            {/* Heading */}
                            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                <h2 className="mt-10 text-center  text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                    Sign in
                                </h2>
                            </div>
        
                            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

                                <form className="space-y-6" onSubmit={this.loginUserWithEmailAndPassword}>

                                    {/* Email */}
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                            Email address
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                required
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                                                value={this.state.email}
                                                onChange={(e) => {
                                                    this.setState({
                                                        email: e.target.value
                                                    });
                                                }} />
                                        </div>
                                    </div>
            
                                    {/* Password */}
                                    <div>
                                        <div className="flex items-center justify-between">
                                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                                Password
                                            </label>
                                            <div className="text-sm">
                                                <button 
                                                    className="font-semibold text-emerald-600 hover:text-emerald-500"
                                                    type="button"
                                                    onClick={() => {
                                                        this.setState({
                                                            showPasswordResetLightbox: true
                                                        })
                                                    }}>
                                                    Forgot password?
                                                </button>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                                                value={this.state.password}
                                                onChange={(e) => {
                                                    this.setState({
                                                        password: e.target.value
                                                    });
                                                }}/>
                                        </div>
                                    </div>
            
                                    {/* Log in button */}
                                    {!this.state.loading ?
                                        <div>
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md bg-emerald-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                                                Log in
                                            </button>
                                        </div>
                                    : null}

                                    {/* Google */}
                                    <div className="mt-10">
                                        <div className="relative">
                                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                <div className="w-full border-t border-gray-200" />
                                            </div>
                                            <div className="relative flex justify-center text-sm font-medium leading-6">
                                                <span className="bg-white px-6 text-gray-900">Or continue with</span>
                                            </div>
                                        </div>
                                        <div className="mt-6">
                                            <button
                                                onClick={() => {
                                                    this.signinWithGoogle();
                                                }}
                                                type="button"
                                                className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-1.5 bg-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/><path d="M1 1h22v22H1z" fill="none"/></svg>
                                                    <span className="text-sm font-semibold leading-6">Google</span>
                                            </button>
                                        </div>    
                                    </div>
   
                                </form>
            
                                {/* Link to sign up */}
                                <p className="mt-10 text-center text-sm text-gray-500">
                                    New to TrackVision?{' '}
                                    <Link to={'/signup'} className="font-semibold leading-6 text-emerald-600 hover:text-emerald-500">
                                        Create account
                                    </Link>
                                </p>

                            </div>
                        </div>
                        
                    </div>

                    <div className='flex items-center justify-center w-full bg-emerald-50 sm:w-1/2'>
                        <div className='m-16'>
                            <img className='rounded-md shadow-lg' src={require('../dashboard.png')} />
                        </div>
                    </div>

                </div>
            </>
        )
    }
});