import React from 'react';
import { withRouter } from '../components/withRouter';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { PlusIcon, ArrowPathIcon } from '@heroicons/react/24/outline'
import { ToastContainer, toast } from 'react-toastify';
import WebService from '../services/webservice';
import Sidebar from '../components/sidebar';
import Header from '../components/header';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { loadStripe } from '@stripe/stripe-js';
import Prices from '../components/prices';
import Account from '../components/account';



export default withRouter(class Settings extends React.Component {

    /**
     * Constructor.
     */
    constructor() {
        super();

        // Initial state.
        this.state = {
            showHeader: true,
            loadingCheckout: false,
            loadingSubscriptions: true,
            loadingSubscription: true,
            loadingPortal: false,
            subscriptions: [],
            user: null,
            tab: WebService.getQueryParam('tab') != null ? WebService.getQueryParam('tab') : 'account',
            tabs: [
                { name: 'Account', id: 'account' },
                { name: 'Plan', id: 'plan' },
            ],
            tiers: [
                {
                    name: 'Basic',
                    mostPopular: false,
                    price: { 
                        monthly: '$19.99', 
                        monthlyId: WebService.basicMonthlyPriceId,
                        annually: '$14.99',
                        annuallyId: WebService.basicYearlyPriceId,
                    },
                    description: 'Everything you need to get started with finding out new winning products',
                    features: [
                        { 
                            label: 'Store tracker',
                            value: '15 stores'
                        },
                        { 
                            label: 'Track products',
                            value: '15 products'
                        },
                        { 
                            label: 'Top Global stores',
                            value: '-'
                        },
                        { 
                            label: 'Top Global products',
                            value: '-'
                        },
                        { 
                            label: 'Customer support',
                            value: '7 days a week'
                        }
                    ]
                },
                {
                    name: 'Professional',
                    mostPopular: true,
                    price: { 
                        monthly: '$39.99', 
                        monthlyId: WebService.professionalMonthlyPriceId,
                        annually: '$29.99',
                        annuallyId: WebService.professionalYearlyPriceId,
                    },
                    description: 'The most popular plan if you are serious about getting started',
                    features: [
                        { 
                            label: 'Store tracker',
                            value: '50 stores'
                        },
                        { 
                            label: 'Track products',
                            value: '50 products'
                        },
                        { 
                            label: 'Top Global stores',
                            value: '30'
                        },
                        { 
                            label: 'Top Global products',
                            value: '20'
                        },
                        { 
                            label: 'Customer support',
                            value: '7 days a week'
                        }
                    ]
                },
                {
                    name: 'Business',
                    mostPopular: false,
                    price: { 
                        monthly: '$79.99', 
                        monthlyId: WebService.businessMonthlyPriceId,
                        annually: '$59.99',
                        annuallyId: WebService.businessYearlyPriceId,
                    },
                    description: 'The biggest plan to maximise your potential of success',
                    features: [
                        { 
                            label: 'Store tracker',
                            value: '100 stores'
                        },
                        { 
                            label: 'Track products',
                            value: '100 products'
                        },
                        { 
                            label: 'Top Global stores',
                            value: '50'
                        },
                        { 
                            label: 'Top Global products',
                            value: '50'
                        },
                        { 
                            label: 'Customer support',
                            value: '7 days a week'
                        }
                    ]
                }
            ]
        };
        
        // Bind functions.
        this.classNames = this.classNames.bind(this);
        this.sendToCheckout = this.sendToCheckout.bind(this);
        this.getPrices = this.getPrices.bind(this);
        this.getSubscriptions = this.getSubscriptions.bind(this);
        this.redirectToCustomerPortal = this.redirectToCustomerPortal.bind(this);

        // Set title
        document.title = 'Settings - Track Vision';
    };

    /**
     * Component did mount.
     */
    componentDidMount() {

        // Auth observer.
        this.authUnsubscribe = onAuthStateChanged(auth, user => {
            if (user) {

                // Get the user details.
                WebService.userDetails(user).then(userDetails => {
                    this.setState({
                        userDetails: userDetails
                    });
                }, error => {
                    toast(error);
                });

                // Set the user.
                this.setState({
                    user: user
                }, () => {

                    // Get the prices.
                    this.getPrices();

                    // Get the user's subscription.
                    this.getSubscriptions();

                });
            }
        });
    };

    /**
     * Component will unmount.
     */
    componentWillUnmount() {

        // Unsubscribe the auth observer.
        this.authUnsubscribe();
    };

    /**
     * Redirects the user to the customer portal.
     */
    redirectToCustomerPortal() {

        // Set loaders.
        this.setState({
            loadingPortal: true,
        });

        // Create the checkout session.
        WebService.redirectToCustomerPortal().then(async url => {

            // Go to the stripe portal.
            window.location.assign(url);

            // Update loaders.
            this.setState({
                loadingPortal: false,
            });

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                loadingPortal: false,
            });

        });

    };

    /**
     * Sends the user to checkout.
     */
    sendToCheckout(priceId) {

        // Set loaders.
        this.setState({
            loadingCheckout: true,
        });

        // Create the checkout session.
        WebService.sendToCheckout(this.state.user.uid, priceId).then(async sessionId => {

            const stripe = await loadStripe(WebService.stripePublishablekey);

            await stripe.redirectToCheckout({sessionId});

            // Update loaders.
            this.setState({
                loadingCheckout: false,
            });

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                loadingCheckout: false,
            });

        });
    };

    /**
     * Gets the subscriptions.
     */
    getPrices() {

        // Set loaders.
        this.setState({
            loadingSubscriptions: true,
        });

        // Create the checkout session.
        WebService.getPrices().then(prices => {

            // console.log(subscriptions);

            // Update loaders.
            this.setState({
                loadingSubscriptions: false,
            });

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                loadingSubscriptions: false,
            });

        });
    };

    /**
     * Gets the users subscription.
     */
    getSubscriptions() {

        // Set loaders.
        this.setState({
            loadingSubscription: true,
        });

        // Create the checkout session.
        WebService.getSubscriptions(this.state.user.uid).then(subscriptions => {
           
            // Update loaders.
            this.setState({
                loadingSubscription: false,
                subscriptions: subscriptions
            });

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                loadingSubscription: false,
            });

        });
    };

    /**
     * Gets the class name.
     * @param  {...any} classes the original classes.
     * @returns 
     */
    classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    };

    /**
     * Render.
     */
    render() {
        return (
            <>

                {/* Toast */}
                <ToastContainer autoClose={3000} theme='dark'/>

                {/* Sidebar */}
                {this.state.showHeader ?
                    <Sidebar
                        title='Tracked Shops'
                        userDetails={this.state.userDetails} />          
                : null}

                {/* Content */}
                <main className="lg:pl-72">

                    {/* Main */}
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">

                        {/* Tabs */}
                        <div>
                            <div className="sm:hidden">
                                <select
                                    id="tabs"
                                    name="tabs"
                                    className="block w-full rounded-md border-gray-300 focus:border-emerald-500 focus:ring-emerald-500"
                                    onChange={(event) => {this.setState({tab: event.target.value.toLowerCase()})}}
                                    defaultValue={this.state.tabs.find((tab) => this.state.tab).name}>
                                        {this.state.tabs.map((tab) => (
                                            <option key={tab.id}>{tab.name}</option>
                                        ))}
                                </select>
                            </div>
                            <div className="hidden sm:block">
                                <nav className="flex space-x-4 justify-center" aria-label="Tabs" style={{cursor: 'pointer'}}>
                                    {this.state.tabs.map((tab) => (
                                        <div
                                            onClick={() => {this.setState({tab: tab.id})}}
                                            key={tab.name}
                                            className={this.classNames(
                                                tab.id == this.state.tab ? 'bg-emerald-100 text-emerald-700' : 'text-gray-500 hover:text-gray-700',
                                                'rounded-md px-3 py-2 text-sm font-medium'
                                            )}
                                            aria-current={tab.current ? 'page' : undefined}>
                                                {tab.name}
                                        </div>
                                    ))}
                                </nav>
                            </div>
                        </div>

                        {/* Account */}
                        {this.state.tab == 'account' ?
                            <Account 
                                user={this.state.user}
                                userDetails={this.state.userDetails} />        
                        : null}

                        {/* Prices */}
                        {this.state.tab == 'plan' ?
                            <Prices
                                tiers={this.state.tiers}
                                subscriptions={this.state.subscriptions}
                                loadingSubscriptions={this.state.loadingSubscriptions}
                                loadingSubscription={this.state.loadingSubscription}
                                loadingCheckout={this.state.loadingCheckout}
                                loadingPortal={this.state.loadingPortal}
                                buyPlan={(priceId) => {
                                    this.sendToCheckout(priceId);
                                }}
                                cancelPlan={() => {
                                    this.redirectToCustomerPortal();
                                }}
                                switchPlan={() => {
                                    this.redirectToCustomerPortal();
                                }}/>
                        :null}
                 
                    </div>

                </main>

            </>
        );
    };
});