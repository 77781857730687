import React from 'react';
import { Fragment } from 'react';
import { withRouter } from './withRouter';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, Bars3Icon, HomeIcon, BuildingStorefrontIcon, ShoppingBagIcon, PlusIcon, UserCircleIcon, RocketLaunchIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom';
import TrackedCounts from './trackedcounts';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { toast } from 'react-toastify';
import WebService from '../services/webservice';
import Lightbox from './lightbox';

export default withRouter(class Limit extends React.Component {

    /**
     * Render.
     */
    render() {
        return (
            <Lightbox
                buttons={(
                    <>
                        <Link to={'/settings'} type="button" className="inline-flex w-full justify-center rounded-md bg-emerald-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-500">
                            Select a plan
                        </Link>
                    </>
                )}
                onDismiss={() => {
                    this.props.onDismiss();
                }}>
                <div className='text-center w-full'>
                    <div className='flex items-center justify-center pb-5'>
                        <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-orange-100">
                            <LockClosedIcon className="h-10 w-10 text-orange-600" aria-hidden="true" />
                        </div>
                    </div>
                    <div className='text-base font-semibold leading-6 text-gray-900'>Shop limit reached</div>
                    <p className='text-sm text-gray-500 pb-3'>Upgrade your plan to access all tools</p>
                </div>
            </Lightbox>
        );
    };
});