import React from 'react';
import { withRouter } from './withRouter';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { PlusIcon, ArrowPathIcon, LockClosedIcon, KeyIcon } from '@heroicons/react/24/outline'
import { toast } from 'react-toastify';
import WebService from '../services/webservice';
import Limit from './limit';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid'
import Lightbox from './lightbox';

export default class Account extends React.Component {

    /**
     * Constructor.
     */
    constructor() {
        super();

        // // Initial state.
        this.state = {
            firstName: '',
            lastName: '',
            loading: false
        };

        // Bind functions.
        this.updateUserDetails = this.updateUserDetails.bind(this);
    };

    /**
     * The component did mount.
     */
    componentDidMount() {
    
        // Set the first name from props.
        let firstName = this.state.firstName;
        if (this.props.userDetails?.firstName != null) {
            firstName = this.props.userDetails.firstName
        }

        // Set the last name from props.
        let lastName = this.state.lastName;
        if (this.props.userDetails?.lastName != null) {
            lastName = this.props.userDetails.lastName
        }

        // Update the state.
        this.setState({
            firstName: firstName,
            lastName: lastName
        })
    };

    /**
     * Component did update.
     * @param {*} previousProps the previous props.
     */
    componentDidUpdate(previousProps) {

        // Update the user details based on props.
        if (previousProps.userDetails?.firstName !== this.props.userDetails?.firstName || 
            previousProps.userDetails?.lastName !== this.props.userDetails?.lastName) {

            this.setState({
                firstName: this.props.userDetails?.firstName,
                lastName: this.props.userDetails?.lastName
            });
        }
    };

    /**
     * Gets the class name.
     * @param  {...any} classes the original classes.
     * @returns 
     */
    classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    };

    /**
     * Updates the user details.
     */
    updateUserDetails() {

        // Update loaders.
        this.setState({
            loading: true,
        });

        // Update the user details.
        WebService.saveUserDetails(
            {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
            }, 
            this.props.user.uid
        ).then(() => {

            toast('Details updated');

            // Update loaders.
            this.setState({
                loading: false,
            });

        }, error => {

            // Show the error.
            toast(error);

            // Update loaders.
            this.setState({
                loading: false,
            });

        });
    };
    
    /**
     * Render.
     */
    render() {
        return (
            <>

                {/* Loading */}



                {this.state.loading || this.props.userDetails == null || this.props.user == null ?
                    <div className='relative'>
                        <div className='absolute h-full rounded-lg w-full mt-8' >
                            <div className='loading mb-6 w-full' style={{ height: '3rem' }}></div>
                            <div className='loading mb-2 w-full' style={{ height: '3rem' }}></div>
                        </div>
                    </div>
                : null}

                {/* User details */}
                {!this.state.loading && this.props.userDetails != null && this.props.user != null ?
                    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none mt-8 fade-in">
                        <div>

                            {/* Header */}
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Account</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-500">
                                Your account details
                            </p>

                            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">

                                {/* First name */}
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">First name</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <input
                                            type="text"
                                            name="first-name"
                                            id="first-name"
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            value={this.state.firstName}
                                            onChange={(e) => {
                                                this.setState({
                                                    firstName: e.target.value
                                                });
                                            }}
                                        />
                                        <button type="button" className="font-semibold text-emerald-600 hover:text-emerald-500" onClick={this.updateUserDetails}>
                                            Update
                                        </button>
                                    </dd>
                                </div>
                                
                                {/* Last name */}
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Last Name</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <input
                                            type="text"
                                            name="first-name"
                                            id="first-name"
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            value={this.state.lastName}
                                            onChange={(e) => {
                                                this.setState({
                                                    lastName: e.target.value
                                                });
                                            }}
                                        />
                                        <button type="button" className="font-semibold text-emerald-600 hover:text-emerald-500" onClick={this.updateUserDetails}>
                                            Update
                                        </button>
                                    </dd>
                                </div>

                                {/* Email */}
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">{this.props.userDetails?.email}</div>
                                    </dd>
                                </div>

                                {/* Trail days */}
                                <div className="pt-6 sm:flex">
                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Trail days remaining</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                        <div className="text-gray-900">{this.props.userDetails?.trailDaysRemaining}</div>
                                    </dd>
                                </div>

                            </dl>
                        </div>

                    </div>
                : null}
            </>
        );
    };
};