import React from 'react';

export default class TrackedCounts extends React.Component {

    /**
     * Render.
     */
    render() {
        return (
            <>   

                {/* Tracked Shops */}
                <div className='bg-green-50 text-green-700 ring-1 ring-inset ring-green-600/20 rounded-lg px-2 py-2 w-20 sm:w-36 mr-2'>
                    <dt className="text-xs font-medium flex">
                        <span className='hidden sm:block'>My Tracked </span> <span className='ml-0 sm:ml-1'>Shops</span>
                    </dt>
                    <dd className="text-sm font-medium text-gray-700">
                        {this.props.userDetails?.dailyShopCount == null ?
                            <>
                                <div className='loading w-full' style={{ height: '1.2rem' }}></div>
                            </>
                        : 
                            <>
                                <div className='fade-in'>
                                    {this.props.userDetails.dailyShopCount}/{'' + this.props.userDetails?.shopLimit}
                                </div>
                            </>
                        }
                    </dd>
                </div>

                {/* Tracked Products */}
                <div className='bg-green-50 text-green-700 ring-1 ring-inset ring-green-600/20 rounded-lg px-2 py-2 w-20 sm:w-36'>
                <dt className="text-xs font-medium flex">
                        <span className='hidden sm:block'>My Tracked </span> <span className='ml-0 sm:ml-1'>Products</span>
                    </dt>
                    <dd className="text-sm font-medium text-gray-700">
                    {this.props.userDetails?.dailyProductCount == null ?
                            <>
                                <div className='loading w-full' style={{ height: '1.2rem' }}></div>
                            </>
                        : 
                            <>
                                <div className='fade-in'>
                                    {this.props.userDetails.dailyProductCount}/{'' + this.props.userDetails?.productLimit}
                                </div>
                            </>
                        }
                    </dd>
                </div>

            </>
        );
    };
};